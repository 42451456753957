<template>
  <!-- Start ML About Area -->
  <div class="about-area pt-0 ptb-80">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="ml-about-img">
            <img src="../../../assets/img/about/1.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="ml-about-content">
            <span class="sub-title">{{ $t("about.sub-title") }}</span>
            <h2 v-if="width >= 1068">{{ $t("about.title") }}</h2>
            <h2 v-else style="text-align: center">{{ $t("about.title") }}</h2>
            <div class="bar"></div>
            <p>{{ $t("about.paragraph-1") }}.</p>
            <p>{{ $t("about.paragraph-2") }}.</p>

            <!-- <a href="#" class="btn btn-secondary">Discover More</a> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 
    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div> 
    -->
  </div>
  <!-- End ML About Area -->
</template>

<script>
export default {
  name: "About",
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>