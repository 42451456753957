<template>
  <!-- Start Mission Partner Area -->
  <section>
    <div class="container">
      <div class="section-title">
        <h2 class="text-center">{{ $t("payment-methods.title") }}</h2>
        <div class="bar"></div>
      </div>

      <div class="row justify-content-center bg-payment-methods">
        <div class="col-lg-3 col-sm-12 mt-3">
          <div
            class="card py-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/1-zelle.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.zelle") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/4-usa.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.usa") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/7-ach.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.ach") }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 mt-3">
          <div
            class="card py-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/2-paypal.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.paypal") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/5-venmo.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.venmo") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/8-bs-prox.png"
              alt="image"
            />
            <p>{{ $t("payment-methods.bs") }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 mt-3">
          <div
            class="card py-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/3-euro.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.euro") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/6-usdt.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.usdt") }}</p>
          </div>
          <div
            class="card py-3 mt-3"
            :style="[width >= 1068 ? { 'max-width': '200px' } : '']"
          >
            <img
              src="../../../assets/img/payment-methods/9-panama.svg"
              alt="image"
            />
            <p>{{ $t("payment-methods.panama") }}</p>
          </div>
        </div>
        <img
          v-if="width >= 1068"
          class="the-dog"
          src="../../../assets/img/payment-methods/the-dog.png"
          alt="image"
        />
      </div>
    </div>
  </section>
  <!-- End Mission Partner Area -->
</template>

<script>
export default {
  name: "PaymentMethods",
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
h3 {
  text-align: center;
}
p {
  margin-top: 5px;
  font-size: 12px;
  color: #3b8f76;
  text-align: center;
}
.bg-payment-methods {
  max-width: 1440px;
  margin: 0 auto 60px auto;
}
div.card {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 1.1rem;
}
img {
  display: block;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}
.the-dog {
  display: block;
  position: absolute;
  height: 250px;
  width: 250px;
  margin: 200px 0 270px 1000px;
}
</style>