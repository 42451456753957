<template>
  <b-modal
    v-model="show"
    size="md"
    id="warning-modal-whatsapp"
    centered
    hide-footer
    hide-header
    content-class="background-modal"
  >
    <div class="wrapper">
      <div class="warning-image-container">
        <img src="../../../assets/img/warning-whatsapp.png" />
      </div>
      <div class="btnBox">
        <b-button class="button" pill @click="show = false">
          {{ $t("warning-modal-whatsapp.button") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "WarningModalWhatsApp",
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style scoped>
.wrapper > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.warning-image-container {
  max-width: 55vh;
  height: auto;
  margin: 0 auto;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 550px) {
  .btnBox {
    margin: -5rem auto;
    z-index: 99;
  }
  .button {
    padding: 0 15px;
    font-size: 0.8rem;
  }
}
@media (max-width: 550px) {
  .btnBox {
    margin: -4rem auto;
    z-index: 99;
  }
  .button {
    padding: 0 12px;
    font-size: 0.7rem;
  }
}
</style>