import { render, staticRenderFns } from "./Swap.vue?vue&type=template&id=c1581374&scoped=true"
import script from "./Swap.vue?vue&type=script&lang=js"
export * from "./Swap.vue?vue&type=script&lang=js"
import style0 from "./Swap.vue?vue&type=style&index=0&id=c1581374&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1581374",
  null
  
)

export default component.exports