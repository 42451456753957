import Dinero from "dinero.js";
const { helpers } = require("vuelidate/lib/validators");

const notAlpha = helpers.regex("notAlpha", /[^A-Za-z]+$/);

export default {
    deleteItem: ({ getters, commit }, { index, optionsSelected, selection }) => {

        const arrayOperations = getters.getArrayOperations;

        const x = arrayOperations.splice(index, 1);
        const { selected } = x[0];

        if (!selected) {
            return;
        }

        if (!optionsSelected.some(ele => ele === "Bolivares")) {
            optionsSelected.push("Bolivares");
        }

        let optionsOperations = optionsSelected;
        commit("setOptionsOperations", optionsOperations);

        if (arrayOperations.length > 0) {

            const optionsUsed = arrayOperations.map((ele) => ele.selected);
            optionsUsed.push(selection);

            arrayOperations.forEach((element) => {
                element.options = optionsSelected.filter(
                    (ele) => ele === element.selected || !optionsUsed.some((x) => x === ele)
                );
            });

        }

        commit("setArrayOperations", arrayOperations)

        commit("setResult", selection);
    },
    async addChange({ getters, dispatch }, { item, selected }) {
        let value = item.monto;
        if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "" && item.selected) {
            let amount = Dinero({
                amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
                currency: selected === "Euro" ? "EUR" : "USD",
                precision: 2,
            });

            const result = getters.getArrayOperations.reduce((acc, ele) => {

                const option = { currency: acc.getCurrency() };
                if (ele.selected === "Bolivares") {
                    option.amount = parseInt(ele.monto.replace(/[,.$€Bs\s]/g, ""), 10);
                } else {
                    let x = ele.result ? ele.result.replace(/[,.$€Bs\s]/g, "") : "0";
                    option.amount = parseInt(x, 10);
                }

                const resultEle = Dinero(option);
                return acc.add(resultEle);

            }, Dinero({ currency: item.selected === "Euro" ? "EUR" : "USD" }));

            const toSend = getters.getToSend ?? Dinero({ currency: selected === "Euro" ? "EUR" : "USD" });
            if (toSend.greaterThanOrEqual(result)) {

                if (item.selected === "Bolivares") {
                    item.monto = amount.add(toSend.subtract(result)).toFormat("$0.00").replace(".", ",");
                } else {
                    const montoATransformar = toSend.subtract(result);

                    let residuo = {};

                    if (selected === "Paypal") {
                        residuo = await dispatch("envCalcularPaypalEnOrigen", { amount: montoATransformar, item, selected });
                    } else if (item.selected === "Paypal") {
                        residuo = await dispatch("envCalcularPaypalEnDestino", { amount: montoATransformar, item, selected });
                    } else {
                        residuo = await dispatch("envCalcularSinPaypal", { amount: montoATransformar, item, selected });
                    }

                    item.monto = amount.add(residuo).toFormat("$0.00").replace(".", ",")
                }

                dispatch("formatterValue", { item, selected } );

            }
        }

    },
    formatterValue: ({ getters, commit, dispatch }, { item, selected }) => {
        let value = item.monto;
        let result = "$0,00";
        if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "" && item.selected) {
            let amount = Dinero({
                amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
                currency: item.selected === "Euro" ? "EUR" : "USD",
                precision: 2,
            });
            if (amount.isNegative()) {
                amount = amount.multiply(-1);
            }
            result = amount.toFormat("$0.00").replace(".", ",");

            if (item.selected !== "Bolivares") {
                const { divisasData } = getters.getRateData;
                const x = selected.toLowerCase();
                const y = item.selected.toLowerCase();
                const rate = parseFloat(divisasData[x][y].replace(",", ".").replace("%", ""));
                item.rate = rate;
            }



            if (selected === "Paypal") {
                switch (item.selected) {
                    case "Bolivares":
                        dispatch("calcularPaypalBolivares", { item, amount });
                        break;
                    default:
                        dispatch("calcularPaypalEnOrigen", { item, amount });
                        break;
                }
            } else {
                if (item.selected === "Paypal") {
                    dispatch("calcularPaypalEnDestino", { item, amount });
                } else if (item.selected === "Bolivares") {
                    dispatch("calcularBolivares", { item, amount, selected });
                } else {
                    dispatch("calcularSinPaypal", { item, amount });
                }
            }

            commit("setResult", selected);

        }
        item.monto = result;
    },
    calcularSinPaypal: (_, { item, amount }) => {
        let total = amount.divide(1 - item.rate / 100);
        const comision = total.subtract(amount);

        /*         const comisionFlag = total.lessThan(Dinero({ amount: 5000, currency: amount.getCurrency() })) && !getters.getNewUserFlag;
        
                if (comisionFlag) {
                    total = total.add(Dinero({ amount: 200, currency: amount.getCurrency() }));
                }
        
                item.comisionFlag = comisionFlag; */

        item.comision = comision.toFormat("$0.00").replace(".", ",");
        item.result = total.toFormat("$0.00").replace(".", ",");
    },
    calcularPaypalEnOrigen: ({ getters }, { item, amount }) => {
        const { rate, rateUSD } = getters.getRateData;
        const receive = amount.convertPrecision(10);


        let total = receive.divide(1 - item.rate / 100);
        /*         const comisionFlag = total.lessThan(Dinero({ amount: 5000, currency: amount.getCurrency() })) && !getters.getNewUserFlag;
        
                if (comisionFlag) {
                    total = total.add(Dinero({ amount: 200, currency: amount.getCurrency() }));
                }
        
                item.comisionFlag = comisionFlag; */

        const comision = total.subtract(receive);
        const totalMasPaypal = total
            .add(rateUSD)
            .divide(1 - rate / 100)
            .convertPrecision(2);

        item.result = totalMasPaypal
            .toFormat("$0.00")
            .replace(".", ",");

        item.comision = comision.toFormat("$0.00").replace(".", ",");
        item.comisionPaypal = totalMasPaypal
            .subtract(total)
            .toFormat("$0.00")
            .replace(".", ",");
    },
    calcularPaypalEnDestino: ({ getters }, { item, amount }) => {
        const { client } = getters.getRateData;
        const { rate, rateUSD } = client;
        const receive = amount.convertPrecision(10);

        let totalMasPaypal = receive.add(rateUSD).divide(1 - rate / 100);

        let total = totalMasPaypal.divide(1 - item.rate / 100);
        const comision = total.subtract(totalMasPaypal);

        /*         let comisionFlag =
                    total.lessThan(Dinero({ amount: 5000, currency: total.getCurrency() })) && !getters.getNewUserFlag;
        
                if (comisionFlag) {
                    total = total.add(Dinero({ amount: 200, currency: total.getCurrency() }));
                }
                item.comisionFlag = comisionFlag; */

        item.result = total.toFormat("$0.00").replace(".", ",");
        item.comision = comision.toFormat("$0.00").replace(".", ",");
        item.comisionPaypal = totalMasPaypal
            .convertPrecision(2)
            .subtract(amount)
            .toFormat("$0.00")
            .replace(".", ",");

    },
    calcularPaypalBolivares: async ({ getters }, { item, amount }) => {
        const { rate, rateUSD, paypal } = getters.getRateData;
        const receive = amount.convertPrecision(10);
        const comisionPaypal = receive.multiply(rate / 100).add(rateUSD);


        item.comisionPaypal = comisionPaypal.toFormat("$0.00").replace(".", ",");

        let total = receive.subtract(comisionPaypal);

        /*         const comisionFlag = total.lessThan(Dinero({ amount: 5000, currency: amount.getCurrency() })) && !getters.getNewUserFlag;
                if (comisionFlag) {
                    total = total.add(Dinero({ amount: 20000000000, currency: amount.getCurrency(), precision: total.getPrecision() }));
                }
                item.comisionFlag = comisionFlag; */
        item.rateVES = paypal;

        item.result = await total.convert("VES", {
            endpoint: new Promise((resolve) =>
                resolve({
                    rates: {
                        VES: paypal,
                    },
                })
            ),
        });
        item.result = item.result.toFormat("$0.00").replace(".", ",").replace("VES", "Bs.");
    },
    calcularBolivares: async ({ getters }, { item, amount, selected }) => {
        const { usd, eur } = getters.getRateData;
        let receive = amount.convertPrecision(10);

        /*         const comisionFlag = receive.lessThan(Dinero({ amount: 5000, currency: amount.getCurrency() })) && !getters.getNewUserFlag;
                if (comisionFlag) {
                    receive = receive.subtract(Dinero({ amount: 20000000000, currency: amount.getCurrency(), precision: receive.getPrecision() }));
                }
        
                item.comisionFlag = comisionFlag; */

        item.rateVES = selected === "Euro" ? eur : usd;

        item.result = await receive.convert("VES", {
            endpoint: new Promise((resolve) =>
                resolve({
                    rates: {
                        VES: selected === "Euro" ? eur : usd,
                    },
                })
            ),
        });
        item.result = item.result.toFormat("$0.00").replace(".", ",").replace("VES", "Bs.");
    },
    calcularBolivaresToMonto: async ({ getters, commit }, { item, selected }) => {
        let value = item.result;
        let monto = selected === "Euro" ? "€0,00" : "$0,00";
        const { usd, eur, paypal, rate, rateUSD } = getters.getRateData;

        let moneda = 0;
        let tipoMoneda = "USD"


        if (item.selected === 'Paypal') {
            moneda = paypal;
        } else if (item.selected === 'Euro') {
            moneda = eur;
            tipoMoneda = "EUR"
        } else {
            moneda = usd;
        }

        item.rateVES = moneda;


        if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
            let amount = Dinero({
                amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
                currency: "VES",
                precision: 2,
            });

            if (amount.isNegative()) {
                amount = amount.multiply(-1);
            }

            amount = await amount.convert(tipoMoneda, {
                endpoint: new Promise((resolve) =>
                    resolve({
                        rates: {
                            [tipoMoneda]: 1 / moneda,
                        },
                    })
                ),
            });

            if (selected === "Paypal") {
                const total = amount.add(rateUSD).divide(1 - rate / 100);
                const comisionPaypal = total.subtract(amount);
                item.comisionPaypal = comisionPaypal.toFormat("$0.00").replace(".", ",");
                amount = total;
            }

            monto = amount.toFormat("$0.00").replace(".", ",");
        }
        item.monto = monto;

        commit("setResult", selected);

    },
    envCalcularPaypalEnOrigen: ({ getters }, { amount, item, selected }) => {

        const { rate, rateUSD, divisasData } = getters.getRateData;
        const x = selected.toLowerCase();
        const y = item.selected.toLowerCase();
        const divisaRate = parseFloat(divisasData[x][y].replace(",", ".").replace("%", ""));


        const usd = amount.convertPrecision(10);

        const comisionPaypal = usd.multiply(rate / 100).add(rateUSD);
        let totalPaypal = usd.subtract(comisionPaypal);

        const totalSwap = totalPaypal.subtract(
            totalPaypal.multiply(divisaRate / 100)
        );

        return totalSwap;        
    },
    envCalcularPaypalEnDestino: ({ getters }, { amount, item, selected }) => {

        const { client, divisasData } = getters.getRateData;
        const { rate, rateUSD } = client;
        const x = selected.toLowerCase();
        const y = item.selected.toLowerCase();
        const divisaRate = parseFloat(divisasData[x][y].replace(",", ".").replace("%", ""));


        const usd = amount.convertPrecision(10);

        const comisionSwap = usd.multiply(divisaRate / 100);
        let totalSwap = usd.subtract(comisionSwap);

        const totalPaypal = totalSwap.subtract(
          totalSwap.multiply(rate / 100).add(rateUSD)
        );

        return totalPaypal;

    },
    envCalcularSinPaypal: ({ getters }, { amount, item, selected }) => {
        const { divisasData } = getters.getRateData;
        const x = selected.toLowerCase();
        const y = item.selected.toLowerCase();
        const divisaRate = parseFloat(divisasData[x][y].replace(",", ".").replace("%", ""));
        const usd = amount.convertPrecision(10);

        const comision = usd.multiply(divisaRate).divide(100);
        const total = usd.subtract(comision);
        return total;
    } 
};