<template>
  <!-- Start FAQ Area -->
  <section>
    <div class="container ptb-80">
      <div class="row justify-content-center mx-3">
        <!-- Start Page Title -->
        <div class="col-12">
          <div class="section-title">
            <h2>{{ $t("faq.title") }}</h2>
            <div class="bar"></div>
          </div>
        </div>
        <!-- End Page Title -->
        <div class="col-12">
          <div class="faq-accordion">
            <VueFaqAccordion :items="myItems" v-slot="itemData">
              <div v-html="itemData.value" v-linkified />
            </VueFaqAccordion>
          </div>
        </div>
      </div>
    </div>

    <!--
      <div class="shape1">
        <img src="../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img src="../../../assets/img/shape5.png" alt="shape" />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      -->

    <!-- <div class="faq-contact">
          <h3>Ask Your Question</h3>
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="text" placeholder="Name" class="form-control" />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Subject"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea
                    name="message"
                    cols="30"
                    rows="6"
                    placeholder="Message"
                    class="form-control"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button class="btn btn-primary" type="submit">
                  Submit Now!
                </button>
              </div>
            </div>
          </form>
        </div> -->
  </section>
  <!-- End FAQ Area -->
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";
export default {
  name: "Faq",
  components: {
    VueFaqAccordion,
  },
  data() {
    return {
      myItems: [
        {
          title: this.$t("faq.question-1"),
          value: this.$t("faq.answer-1"),
        },
        {
          title: this.$t("faq.question-2"),
          value:
            this.$t("faq.answer-2-1") +
            this.$t("faq.answer-2-1-1") +
            this.$t("faq.answer-2-1-2") +
            this.$t("faq.answer-2-1-3") +
            this.$t("faq.answer-2-1-4") +
            this.$t("faq.answer-2-1-5-1") +
            this.$t("faq.answer-2-1-5-2") +
            this.$t("faq.answer-2-2") +
            this.$t("faq.answer-2-3"),
        },
        {
          title: this.$t("faq.question-3"),
          value: this.$t("faq.answer-3"),
        },
        {
          title: this.$t("faq.question-4"),
          value: this.$t("faq.answer-4-1") + this.$t("faq.answer-4-2"),
        },
        {
          title: this.$t("faq.question-5"),
          value: this.$t("faq.answer-5-1") + this.$t("faq.answer-5-2"),
        },
        {
          title: this.$t("faq.question-6"),
          value:
            this.$t("faq.answer-6-1") +
            this.$t("faq.answer-6-2") +
            this.$t("faq.answer-6-3"),
        },
        {
          title: this.$t("faq.question-7"),
          value: this.$t("faq.answer-7"),
        },
        {
          title: this.$t("faq.question-8"),
          value:
            this.$t("faq.answer-8-1") +
            this.$t("faq.answer-8-2") +
            this.$t("faq.answer-8-3") +
            this.$t("faq.answer-8-4") +
            this.$t("faq.answer-8-5"),
        },
        {
          title: this.$t("faq.question-9"),
          value: this.$t("faq.answer-9"),
        },
        {
          title: this.$t("faq.question-10"),
          value: this.$t("faq.answer-10"),
        },
        {
          title: this.$t("faq.question-11"),
          value: this.$t("faq.answer-11"),
        },
        {
          title: this.$t("faq.question-12"),
          value:
            this.$t("faq.answer-12-1") +
            this.$t("faq.answer-12-2") +
            this.$t("faq.answer-12-3"),
        },
        {
          title: this.$t("faq.question-13"),
          value: this.$t("faq.answer-13-1") + this.$t("faq.answer-13-2"),
        },
      ],
    };
  },
  beforeUpdate() {
    this.myItems = [
      {
        title: this.$t("faq.question-1"),
        value: this.$t("faq.answer-1"),
      },
      {
        title: this.$t("faq.question-2"),
        value: this.$t("faq.answer-2"),
      },
      {
        title: this.$t("faq.question-3"),
        value: this.$t("faq.answer-3-1") + this.$t("faq.answer-3-2"),
      },
      {
        title: this.$t("faq.question-4"),
        value: this.$t("faq.answer-4-1") + this.$t("faq.answer-4-2"),
      },
      {
        title: this.$t("faq.question-5"),
        value:
          this.$t("faq.answer-5-1") +
          this.$t("faq.answer-5-2") +
          this.$t("faq.answer-5-3"),
      },
      {
        title: this.$t("faq.question-6"),
        value: this.$t("faq.answer-6"),
      },
      {
        title: this.$t("faq.question-7"),
        value:
          this.$t("faq.answer-7-1") +
          this.$t("faq.answer-7-2") +
          this.$t("faq.answer-7-3") +
          this.$t("faq.answer-7-4") +
          this.$t("faq.answer-7-5"),
      },
      {
        title: this.$t("faq.question-8"),
        value: this.$t("faq.answer-8"),
      },
      {
        title: this.$t("faq.question-9"),
        value: this.$t("faq.answer-9"),
      },
      {
        title: this.$t("faq.question-10"),
        value: this.$t("faq.answer-10"),
      },
      {
        title: this.$t("faq.question-11"),
        value:
          this.$t("faq.answer-11-1") +
          this.$t("faq.answer-11-2") +
          this.$t("faq.answer-11-3"),
      },
      {
        title: this.$t("faq.question-12"),
        value: this.$t("faq.answer-12-1") + this.$t("faq.answer-12-2"),
      },
      {
        title: this.$t("faq.question-13"),
        value: this.$t("faq.answer-13"),
      },
    ];
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}
</style>