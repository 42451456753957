<template>
  <!-- Start Navbar Area -->
  <header
    id="header"
    :class="['headroom navbar-style-three', { 'is-sticky': isSticky }]"
  >
    <div class="startp-nav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img class="ml-4" src="../../assets/img/logo.svg" alt="logo" />
          </router-link>

          <b-navbar-toggle target="navbarSupportedContent"></b-navbar-toggle>

          <b-collapse
            class="collapse navbar-collapse mean-menu"
            id="navbarSupportedContent"
            is-nav
          >
            <ul class="navbar-nav nav">
              <!-- Localization -->
              <div class="d-flex align-items-center">
                <b-dropdown
                  variant="light"
                  size="sm"
                  toggle-class="language-button"
                >
                  <template slot="button-content">
                    <span class="name">{{
                      selectedLanguage.label.toUpperCase()
                    }}</span>
                  </template>
                  <b-dropdown-item
                    v-for="(l, index) in languages"
                    :key="index"
                    @click="changeLocale(l.language)"
                    >{{ l.label.toUpperCase() }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>

              <!-- <li class="nav-item">
                <router-link to="/" class="nav-link">{{
                  $t("header.about-us")
                }}</router-link>
              </li> -->

              <li class="nav-item">
                <router-link to="/calculators" class="nav-link">{{
                  $t("header.calculators")
                }}</router-link>
              </li>

              <li class="nav-item">
                <router-link to="/how-to-swap" class="nav-link">{{
                  $t("header.how-to-swap")
                }}</router-link>
              </li>

              <div
                v-if="width < 768"
                class="d-flex align-items-center justify-content-left"
              >
                <!-- Register -->
                <!-- <a :href="registerURL" target="_blank" class="btn btn-light">{{
                  $t("header.register")
                }}</a> -->
                <!-- Login 
                <a :href="loginURL" target="_blank" class="btn btn-primary">{{
                  $t("header.login")
                }}</a>-->
              </div>

              <!-- <li class="nav-item">
                <a href="#" class="nav-link"
                  >Pages <feather type="chevron-down"></feather
                ></a>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <router-link to="/faq" class="nav-link">FAQ</router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/coming-soon" class="nav-link"
                      >Coming Soon</router-link
                    >
                  </li>

                  <li class="nav-item">
                    <router-link to="/not-found" class="nav-link"
                      >404 Error</router-link
                    >
                  </li>
                </ul>
              </li> -->
            </ul>
          </b-collapse>

          <div class="others-option">
            <!-- Register -->
            <!-- <a :href="registerURL" target="_blank" class="btn btn-light ml-3">{{
              $t("header.register")
            }}</a> -->
            <!-- Login
            <a :href="loginURL" target="_blank" class="btn btn-primary ml-3">{{
              $t("header.login")
            }}</a> -->
          </div>
        </nav>
      </div>
    </div>
  </header>
  <!-- End Navbar Area -->
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "HeaderThree",
  data() {
    return {
      isSticky: false,
      languages: [
        { label: "English", language: "en" },
        { label: "Español", language: "es" },
      ],
      selectedLanguage: { label: "Español", language: "es" },
      loginURL: "https://venezuela-exchanges.web.app/login",
      registerURL: "https://venezuela-exchanges.web.app/register/user",
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      that.isSticky = scrollPos >= 100;
    });
  },

  computed: {
    shoppingCart() {
      return this.$store.state.cart;
    },
  },

  methods: {
    ...mapActions(["setLang"]),
    changeLocale(locale) {
      if (locale === "en")
        this.selectedLanguage = { label: "English", language: "en" };
      else this.selectedLanguage = { label: "Español", language: "es" };
      this.setLang(this.selectedLanguage.language);
    },
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
.flags {
  font-size: 22px;
  cursor: pointer;
}
.language-button {
  background: whitesmoke;
  border: initial;
  font-size: 0.8rem;
  color: greenyellow;
  padding: 0.6rem 1rem;
}
</style>