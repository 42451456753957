<template>
  <div class="mb-4">
    <div v-if="isOnLanding" class="section-title mb-0">
      <h2 class="text-center">{{ $t("calculator-landing.title") }}</h2>
      <div class="bar"></div>
    </div>
    <div class="calculator-container">
      <b-tabs v-model="tabIndex" class="pt-3" content-class="mt-3" fill>
        <!-- <b-tab :title="$t('calculator-vex.sell-bs-usdvex')">
          <CalculatorUsdVex v-if="rateData.usdVEX" :rateData="rateData" />
        </b-tab> -->
        <b-tab :title="$t('calculator-vex.sell-usd-bs')">
          <CalculatorModsToBs />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
// import CalculatorUsdVex from './CalculatorUsdVex';
import CalculatorModsToBs from '../../other-pages/calculators/components/ModsToBs'

export default {
  props: {
    rateData: {
      default: {},
    },
    isOnLanding: {
      default: true
    }
  },
  name: "CalculatorLanding",
  components: {
    // CalculatorUsdVex,
    CalculatorModsToBs
  },
  data() {
    return {
      tabIndex: 1
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-info', 'text-light', 'rounded']
      } else {
        return ['bg-light', 'text-info', 'rounded']
      }
    }
  },
};
</script>

<style scoped lang="scss">
p {
  line-height: 1.5 !important;
}
.calculator-container {
  background: #f7f7f8;
  border-radius: 20px;
  padding: 0 25px;
  font-size: "12px";
}
</style>
