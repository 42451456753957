/* eslint-disable no-console */
import VueRouter from 'vue-router';
// Pages
import LandingPage from './components/landing/LandingPage';
import HowToSwap from './components/other-pages/how-to-swap/HowToSwap';
import Calculators from './components/other-pages/calculators/Calculators';
import NotFound from './components/other-pages/not-found/NotFound';
import ComingSoon from './components/other-pages/coming-soon/ComingSoon';
// import zonaPruebas from './components/other-pages/zona-Pruebas/zonaPruebas';

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes: [
      { path: '/', component: LandingPage },
      { path: '/how-to-swap', component: HowToSwap },
      { path: '/calculators', component: Calculators },
      { path: '/coming-soon', component: ComingSoon },
      { path: '/not-found', component: NotFound },
      /* { path: '/zona-pruebas', component: zonaPruebas } */
    ]
});

// Simple Sitemap Generation
/*function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://venezuelaexchanges.com')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

console.log(getRoutesXML());
*/