<template>
  <!-- Start Preloader Area -->
  <div class="preloader">
    <div class="spinner"></div>
  </div>
  <!-- End Preloader Area -->
</template>

<script>
export default {
  name: "PreLoader"
};
</script>