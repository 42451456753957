<template>
  <div>
    <div class="row justify-content-center mx-3">
      <!-- Start ¿Cómo ser un cliente VEX? Page Title -->
      <div class="col-12 page-title-area">
        <div class="container">
          <div class="section-title mb-3">
            <h2 style="text-align: center">{{ $t("how-to-swap.title1") }}</h2>
            <div class="bar" />
            <p style="text-align: center">
              {{ $t("how-to-swap.description1") }}
            </p>
          </div>
          <b-button v-b-toggle.collapse-1>
            <div class="d-flex align-items-center">
              {{ $t("how-to-swap.steps") }}<feather type="corner-right-down" />
            </div>
          </b-button>
        </div>
        <!--
      <div class="shape1">
        <img src="../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img
          src="../../../assets/img/shape5.png"
          style="width: 150px"
          alt="shape"
        />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../assets/img/shape2.svg" alt="shape" />
      </div>
      --></div>
      <!-- End ¿Cómo ser un cliente VEX? Page Title -->

      <!-- Start ¿Cómo ser un cliente VEX? Area -->

      <div class="col-sm-12 col-md-8">
        <b-collapse id="collapse-1">
          <!-- Step 1 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/1-1.svg"
              alt="image"
            />
            <p v-html="$t('how-to-swap.slide1-step1')" />
          </div>

          <!-- Step 2 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="p-1 mr-3 list-image"
              src="../../../assets/img/how-to-swap/1-2.svg"
              alt="image"
            />
            <div>
              <p class="mb-0" v-html="$t('how-to-swap.slide1-step2-1')" />
              <p v-html="$t('how-to-swap.slide1-step2-2')" />
              <p class="mb-0" v-html="$t('how-to-swap.slide1-step2-3')" />
              <p v-html="$t('how-to-swap.slide1-step2-4')" />
            </div>
          </div>

          <!-- Step 3 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/1-3.svg"
              alt="image"
            />
            <p v-html="$t('how-to-swap.slide1-step3')" />
          </div>

          <!-- Step 4 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/1-4.png"
              alt="image"
            />
            <div>
              <p class="mb-0" v-html="$t('how-to-swap.slide1-step4-1')" />
              <div class="d-flex">
                <p v-html="$t('how-to-swap.slide1-step4-2')" />
                <p v-html="$t('how-to-swap.slide1-step4-3')" />
              </div>
            </div>
          </div>

          <!-- Step 5 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start mb-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/1-5.svg"
              alt="image"
            />
            <div>
              <p class="mb-3" v-html="$t('how-to-swap.slide1-step5-1') + '.'" />
              <p v-html="$t('how-to-swap.slide1-step5-2')" />
            </div>
          </div>
        </b-collapse>
      </div>

      <!-- End ¿Cómo ser un cliente VEX? Area -->

      <!-- Separator -->
      <div class="col-12">
        <hr class="hr" />
      </div>

      <!-- Start ¿Cómo cambiar? Page Title -->
      <div class="col-12 page-title-area pt-3">
        <div class="container">
          <div class="section-title mb-3">
            <h2 style="text-align: center">{{ $t("how-to-swap.title2") }}</h2>
            <div class="bar" />
            <p v-html="$t('how-to-swap.description2')"></p>
          </div>
          <b-button v-b-toggle.collapse-2 class="mb-3">
            <div class="d-flex align-items-center">
              {{ $t("how-to-swap.steps") }}<feather type="corner-right-down" />
            </div>
          </b-button>
        </div>
        <!--
        <div class="shape1">
          <img src="../../../assets/img/shape1.png" alt="shape" />
        </div>
        <div class="shape2 rotateme">
          <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
        <div class="shape3">
          <img src="../../../assets/img/shape3.svg" alt="shape" />
        </div>
        <div class="shape4">
          <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape5">
          <img
            src="../../../assets/img/shape5.png"
            style="width: 150px"
            alt="shape"
          />
        </div>
        <div class="shape6 rotateme">
          <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape7">
          <img src="../../../assets/img/shape4.svg" alt="shape" />
        </div>
        <div class="shape8 rotateme">
          <img src="../../../assets/img/shape2.svg" alt="shape" />
        </div>
      --></div>
      <!-- End ¿Cómo cambiar? Page Title -->

      <!-- Start ¿Cómo cambiar? Area -->
      <div class="col-sm-12 col-md-8">
        <b-collapse id="collapse-2">
          <!-- Step 1 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/2-1.svg"
              alt="image"
            />
            <p v-html="$t('how-to-swap.slide2-step1')" />
          </div>

          <!-- Step 2 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 mr-5 list-image"
              src="../../../assets/img/how-to-swap/2-2.svg"
              alt="image"
            />
            <p v-html="$t('how-to-swap.slide2-step2') + '.'" />
          </div>

          <!-- Step 3 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/2-3.svg"
              alt="image"
            />
            <p v-html="$t('how-to-swap.slide2-step3')" />
          </div>

          <!-- Step 4 -->
          <div
            class="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start my-3"
          >
            <img
              class="m-3 list-image"
              src="../../../assets/img/how-to-swap/2-4.svg"
              alt="image"
            />
            <div>
              <p class="mb-3" v-html="$t('how-to-swap.slide2-step4-1')" />
              <p v-html="$t('how-to-swap.slide2-step4-2')" />
            </div>
          </div>
        </b-collapse>
      </div>
      <!-- End ¿Cómo cambiar? Area -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HowToSwap",
  data() {
    return {
      myItems: [
        {
          title: this.$t("how-to-swap.title1"),
          value:
            this.$t("how-to-swap.description1") +
            this.$t("how-to-swap.slide1-step1") +
            this.$t("how-to-swap.slide1-step2-1") +
            this.$t("how-to-swap.slide1-step2-2") +
            this.$t("how-to-swap.slide1-step2-3") +
            this.$t("how-to-swap.slide1-step2-4") +
            this.$t("how-to-swap.slide1-step3") +
            this.$t("how-to-swap.slide1-step4-1") +
            this.$t("how-to-swap.slide1-step4-2") +
            this.$t("how-to-swap.slide1-step5-1") +
            this.$t("how-to-swap.slide1-step5-2"),
        },
        {
          title: this.$t("how-to-swap.title2"),
          value:
            this.$t("how-to-swap.description2") +
            this.$t("faq.answer-2-1-1") +
            this.$t("faq.answer-2-1-2") +
            this.$t("faq.answer-2-1-3") +
            this.$t("faq.answer-2-1-4") +
            this.$t("faq.answer-2-1-5-1") +
            this.$t("faq.answer-2-1-5-2") +
            this.$t("faq.answer-2-2") +
            this.$t("faq.answer-2-3"),
        },
      ],
    };
  },
  beforeUpdate() {
    this.myItems = [
      {
        title: this.$t("how-to-swap.title1"),
        value:
          this.$t("how-to-swap.description1") +
          this.$t("how-to-swap.slide1-step1") +
          this.$t("how-to-swap.slide1-step2-1") +
          this.$t("how-to-swap.slide1-step2-2") +
          this.$t("how-to-swap.slide1-step2-3") +
          this.$t("how-to-swap.slide1-step2-4") +
          this.$t("how-to-swap.slide1-step3") +
          this.$t("how-to-swap.slide1-step4-1") +
          this.$t("how-to-swap.slide1-step4-2") +
          this.$t("how-to-swap.slide1-step5-1") +
          this.$t("how-to-swap.slide1-step5-2"),
      },
      {
        title: this.$t("how-to-swap.title2"),
        value:
          this.$t("how-to-swap.description2") +
          this.$t("faq.answer-2-1-1") +
          this.$t("faq.answer-2-1-2") +
          this.$t("faq.answer-2-1-3") +
          this.$t("faq.answer-2-1-4") +
          this.$t("faq.answer-2-1-5-1") +
          this.$t("faq.answer-2-1-5-2") +
          this.$t("faq.answer-2-2") +
          this.$t("faq.answer-2-3"),
      },
    ];
  },
};
</script>

<style scoped>
.list-image {
  width: 150px;
  height: 150px;
  padding: 5px;
  display: inline-block;
}
/* .hr {
  border: 1px solid;
} */
</style>
