<template>
  <!-- Start ML Services Area -->
  <section>
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("why-choose-us.title") }}</h2>
        <div class="bar"></div>
      </div>

      <div class="row mx-3">
        <div class="col-sm-6 col-lg-3">
          <div class="single-solutions-box">
            <div class="image text-center mb-4">
              <img src="../../../assets/img/why-choose-us/1.png" alt="image" />
            </div>
            <h3 class="my-3">{{ $t("why-choose-us.sub-title-1") }}</h3>
            <p>{{ $t("why-choose-us.box-text-1") }}.</p>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="single-solutions-box">
            <div class="image text-center mb-4">
              <img src="../../../assets/img/why-choose-us/2.png" alt="image" />
            </div>
            <h3 class="my-3">{{ $t("why-choose-us.sub-title-2") }}</h3>
            <p>{{ $t("why-choose-us.box-text-2") }}.</p>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="single-solutions-box">
            <div class="image text-center mb-4">
              <img src="../../../assets/img/why-choose-us/3.png" alt="image" />
            </div>
            <h3 class="my-3">{{ $t("why-choose-us.sub-title-3") }}</h3>
            <p>{{ $t("why-choose-us.box-text-3") }}.</p>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3">
          <div class="single-solutions-box">
            <div class="image text-center mb-4">
              <img src="../../../assets/img/why-choose-us/4.png" alt="image" />
            </div>
            <h3 class="my-3">{{ $t("why-choose-us.sub-title-4") }}</h3>
            <p>{{ $t("why-choose-us.box-text-4") }}.</p>
          </div>
        </div>
      </div>
    </div>

    <!--
    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    -->
  </section>
  <!-- End ML Services Area -->
</template>

<script>
export default {
  name: "Why-Choose-Us",
};
</script>

<style scoped>
h2,
h3 {
  text-align: center;
}
img {
  width: 150px;
}
</style>