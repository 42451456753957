<template>
  <div>
    <!-- Start Calculadora De Intercambio De Saldos Page Title -->
    <div class="page-title-area">
      <div class="container">
        <div class="section-title">
          <h2 style="text-align: center">
            {{ $t("calculators.subtitle2-1") }}
          </h2>
          <div class="bar"></div>
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-md-2 col-sm-12 mb-4">
              <img
                src="../../../../assets/img/logo-rounded.svg"
                alt="vex logo"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mx-4">
              <p class="description mb-3">
                {{ $t("calculators.description2-1") }}.
              </p>
              <p class="description" v-html="$t('calculators.description2-2')">
                .
              </p>
              <!-- <p class="description mt-3">
                {{ $t("calculators.description2-3") }}.
              </p> -->
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class="shape1">
        <img src="../../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img
          src="../../../../assets/img/shape5.png"
          style="width: 150px"
          alt="shape"
        />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../../assets/img/shape2.svg" alt="shape" />
      </div>
      -->
    </div>
    <!-- End Calculadora De Intercambio De Saldos Page Title -->

    <!-- Start comisión swap -->
    <div>
      <h3 class="text-center">
        {{ $t("calculators.swap-fee") }}
      </h3>
      <div class="row justify-content-center align-items-center mx-3">
        <div class="col-lg-2 justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.origin") }}</p>
          <div class="form-group w-70 mb-0">
            <b-form-select
              v-model="origin"
              :options="originOptions"
              @change="reset"
              plain
            />
          </div>
        </div>
        <div class="col-lg-2 justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.destination") }}</p>
          <div class="form-group w-70 mb-0">
            <b-form-select
              v-model="destination"
              :options="destinyOptions"
              @change="reset"
              plain
            />
          </div>
        </div>
        <div class="col-lg-2 justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.swap-fee") }}</p>
          <div class="input-group text-right">
            <b-form-input
              v-model="divisaRate"
              type="text"
              class="form-control text-right"
              disabled
            />
            <span class="append">%</span>
          </div>
        </div>
      </div>
      <!-- End comisión swap -->
    </div>

    <div v-if="origin === 'Paypal' && destination === 'Paypal'">
      <p class="mt-3 text-center">
        {{
          $t("calculators.swapSendPaypal") +
          ": " +
          vex.rate.toString().replace(".", ",") +
          "%" +
          " + " +
          vex.rateUSD.toFormat("$0.00").replace(".", ",")
        }}
      </p>
    </div>

    <!-- Start Comisión PayPal Area -->
    <div v-if="origin === 'Paypal' || destination === 'Paypal'" class="mb-5">
      <p class="mb-3" style="text-align: center">
        {{ $t("calculators.subtitle1-1") }}
      </p>
      <form>
        <div class="row justify-content-center align-items-center mx-3">
          <div class="col-lg-2 col-sm-12">
            <div class="input-group text-right">
              <b-form-input
                v-model="rate"
                type="text"
                class="text-right"
                :formatter="formatterRate"
                :disabled="origin === 'Paypal' && destination === 'Paypal'"
                @click="cursorHandler"
              />
              <span class="append">%</span>
            </div>
          </div>
          <span>+</span>
          <div class="col-lg-2 col-sm-12">
            <b-form-input
              v-model="rateFixed"
              type="text"
              class="text-right"
              :formatter="formatterRateUSD"
              :disabled="origin === 'Paypal' && destination === 'Paypal'"
              @click="cursorHandler"
            />
          </div>
        </div>
      </form>
    </div>
    <!-- End Comisión PayPal Area -->

    <!-- Inicio de mensaje de comision por - de 50$ -->

    <!-- <div
      v-if="sendFlag || receiverFlag"
      class="row justify-content-center align-items-center mx-1 mb-3"
    >
      <p class="description">{{ $t("calculators.description1-4") }}.</p>
    </div> -->

    <div class="row justify-content-center align-items-center mx-1 mb-3">
      <label for="newUserToggleSwap" class="mb-0">
        {{ $t("calculators.isNewUser") }}
      </label>
      <b-form-checkbox
        class="ml-2 mr-n2"
        id="newUserToggleSwap"
        v-model="isNewUser"
        name="is-new-user-toggle"
        switch
        size="md"
      >
      </b-form-checkbox>
    </div>

    <div class="row justify-content-center mx-1 mb-3">
      <div class="col-lg-4 col-sm-12 mb-3">
        <p>{{ $t("calculator-landing.isNewUserDescription", {
              cobroMinimo: comisionString,
              montoMinimo: montoMinimoString,
            }) }}.</p>
      </div>
    </div>

    <!-- Fin de mensaje de comision por - de 50$ -->

    <!-- Start Calculadora De Intercambio De Saldos Area -->
    <div class="row justify-content-center align-items-center mx-3">
      <!-- Start Recibir Area -->
      <div class="col-lg-4 col-sm-12 mb-3">
        <div class="d-flex justify-content-start">
          <h3 class="mb-3">
            {{ $t("calculators.subtitle2-2") }}
          </h3>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.receive2-1") }}</p>
          <div class="form-group w-50 mb-0">
            <b-form-input
              v-model="receiveUsdOpe1"
              type="text"
              class="form-control text-right"
              :formatter="formatterReceiver"
              @click="cursorHandler"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.receive2-2") }}</p>
          <p>{{ receiveUsdOpe2 }}</p>
        </div>

        <div
          v-if="receiverFlag"
          class="d-flex justify-content-between align-items-center mb-3"
        >
          <p class="mb-0">{{ $t("calculators.ce") }}</p>
          <p>{{ comisionString }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.receive2-3") }}</p>
          <p>{{ receiveUsdOpe3 }}</p>
        </div>
        <div
          v-if="this.origin === 'Paypal' || this.destination === 'Paypal'"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">{{ $t("calculators.send1-3") }}</p>
          <p>{{ receiveUsdOpe4 }}</p>
        </div>
      </div>
      <!-- End Recibir Area -->

      <!-- Start Enviar Area -->
      <div class="col-lg-4 col-sm-12 mb-3">
        <div class="d-flex justify-content-start">
          <h3 class="mb-3">
            {{ $t("calculators.subtitle2-3") }}
          </h3>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.send1-1") }}</p>
          <div class="form-group w-50 mb-0">
            <b-form-input
              v-model="sendUsdOpe1"
              type="text"
              class="form-control text-right"
              :formatter="formatterSend"
              @click="cursorHandler"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.send2-2") }}</p>
          <p>{{ sendUsdOpe2 }}</p>
        </div>

        <div
          v-if="sendFlag"
          class="d-flex justify-content-between align-items-center mb-3"
        >
          <p class="mb-0">{{ $t("calculators.ce") }}</p>
          <p>{{ comisionString }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.send2-3") }}</p>
          <p>{{ sendUsdOpe3 }}</p>
        </div>
        <div
          v-if="this.origin === 'Paypal' || this.destination === 'Paypal'"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">{{ $t("calculators.send1-3") }}</p>
          <p>{{ sendUsdOpe4 }}</p>
        </div>
      </div>
      <!-- End Enviar Area -->

      <!-- Start Consideraciones Area -->
      <div class="col-sm-9 mb-3">
        <p class="my-3">
          <span class="font-weight-bold"
            >{{ $t("calculators.considerations") }}:
          </span>
          {{ $t("calculators.considerations1") }} {{ rate }}%
          <span v-if="rateFixed != '$0,00'">+ {{ rateFixed }}</span>
          {{ $t("calculators.considerations2") }}.
        </p>
      </div>
      <!-- End Consideraciones Area -->
    </div>
    <!-- End Calculadora De Intercambio De Saldos Area -->
  </div>
</template>

<script>
import Dinero from "dinero.js";
import { mapActions } from "vuex";

const { helpers } = require("vuelidate/lib/validators");

// const decimal = helpers.regex('decimal', /^(\d+(?:[\.\,]\d{0,2})?)$/);
const notAlpha = helpers.regex("notAlpha", /[^A-Za-z]+$/);

export default {
  props: {
    rates: {
      default: "",
    },
  },
  data() {
    return {
      comision: null,
      comisionString: "",
      montoMinimoString: "",
      ce: "$2,00",
      isNewUser: false,
      sendFlag: false,
      receiverFlag: false,
      divisasRate: {},
      divisaRate: "",
      rate: "",
      rateUSD: "",
      rateFixed: "",
      receiveUsdOpe1: "$0,00",
      receiveUsdOpe2: "N/A",
      receiveUsdOpe3: "N/A",
      receiveUsdOpe4: "N/A",
      sendUsdOpe1: "$0,00",
      sendUsdOpe2: "N/A",
      sendUsdOpe3: "N/A",
      sendUsdOpe4: "N/A",
      origin: "",
      destination: "",
      client: {},
      vex: {},
      originOptions: [],
      destinyOptions: [],
    };
  },
  async created() {
    const { rate, rateUSD, divisasData, client, comision } = this.rates;

    this.comision = {
      comision: Dinero({ amount: comision.comision }),
      montoMinimo: Dinero({ amount: comision.montoMinimo })
    };

    this.comisionString = this.comision.comision.toFormat("$0.00").replace(".", ",");
    this.montoMinimoString = this.comision.montoMinimo.toFormat("$0.00").replace(".", ",");

    this.rate = rate;
    this.rateUSD = Dinero({
      amount: parseInt(rateUSD.replace(/[,.$€\s]/g, ""), 10),
    });
    this.divisasRate = divisasData;
    this.rateFixed = this.rateUSD.toFormat("$0.00").replace(".", ",");
    this.client = {
      rate: parseFloat(client.rate.replace(",", "."), 10),
      rateUSD: Dinero({
        amount: parseInt(client.rateUSD.replace(/[,.$€\s]/g, ""), 10),
      }),
    };
    this.vex = {
      rate: parseFloat(rate.replace(",", "."), 10),
      rateUSD: Dinero({
        amount: parseInt(rateUSD.replace(/[,.$€\s]/g, ""), 10),
      }),
    };
    const divisasKeys = Object.keys(this.divisasRate).reduce((acc, ele) => {
      if(ele === "euro"){
        return acc;
      }
      if (ele !== "usdt") {
        acc.push(this.toUpperCase(ele));
      } else {
        acc.push(ele.toUpperCase());
      }
      return acc;
    }, []);
    this.originOptions = divisasKeys.filter((ele) => (ele !== "Zinli"));
    this.destinyOptions = divisasKeys;
  },
  watch: {
    origin(val) {
      val = val.toLowerCase();
      if (this.destination === "" || !val) {
        this.divisaRate = "N/A";
      } else {
        this.divisaRate = this.divisasRate[val][this.destination.toLowerCase()];
        if (val === "paypal" && this.destination !== "Paypal") {
          const rateToShow = this.vex.rate;
          const { rateUSD } = this.vex;
          this.rate = rateToShow.toString().replace(".", ",");
          this.rateFixed = rateUSD.toFormat("$0.00").replace(".", ",");
          this.rateUSD = rateUSD;
        } else {
          this.rate = this.client.rate.toString().replace(".", ",");
          this.rateUSD = this.client.rateUSD;
          this.rateFixed = this.rateUSD.toFormat("$0.00").replace(".", ",");
        }
        this.reset();
      }
    },
    destination(val) {
      val = val.toLowerCase();
      if (this.origin === "" || !val) {
        this.divisaRate = "N/A";
      } else {
        this.divisaRate = this.divisasRate[this.origin.toLowerCase()][val];
        if (val === "paypal") {
          this.divisaRate = this.divisasRate[this.origin.toLowerCase()][val];
          this.rate = this.client.rate.toString().replace(".", ",");
          this.rateUSD = this.client.rateUSD;
          this.rateFixed = this.rateUSD.toFormat("$0.00").replace(".", ",");
        } else if (this.origin === "Paypal") {
          const rateToShow = this.vex.rate;
          this.divisaRate = this.divisasRate[this.origin.toLowerCase()][val];
          const { rateUSD } = this.vex;
          this.rate = rateToShow.toString().replace(".", ",");
          this.rateFixed = rateUSD.toFormat("$0.00").replace(".", ",");
          this.rateUSD = rateUSD;
        }
        this.reset();
      }
    },
    isNewUser() {
      if (
        this.receiveUsdOpe1 !== "$0,00" &&
        this.divisaRate &&
        this.divisaRate !== "N/A"
      ) {
        const amount = Dinero({
          amount: parseInt(this.receiveUsdOpe1.replace(/[,.$€Bs\s]/g, ""), 10),
        });
        if (this.origin === "Paypal" && this.destination === "Paypal") {
          this.paypalPaypalReceive(amount);
        } else if (this.destination !== "Paypal" && this.origin !== "Paypal") {
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularRecibirSinPaypal(rate, amount);
        } else if (this.receiveUsdOpe1 !== "$0,00") {
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularRecibirConPaypal(rate, amount);
        }
      }

      if (
        this.sendUsdOpe1 !== "$0,00" &&
        this.divisaRate &&
        this.divisaRate !== "N/A"
      ) {
        const amount = Dinero({
          amount: parseInt(this.sendUsdOpe1.replace(/[,.$€Bs\s]/g, ""), 10),
        });
        if (this.origin === "Paypal" && this.destination === "Paypal") {
          this.paypalPaypalSend(amount);
        } else if (this.destination !== "Paypal" && this.origin !== "Paypal") {
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularEnvioSinPaypal(rate, amount);
        } else {
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularEnvioConPaypal(rate, amount);
        }
      }
    },
  },
  methods: {
    cursorHandler(event) {
      event.target.selectionStart = 10000;
      event.target.selectionEnd = 10000;
      return event;
    },
    ...mapActions(["getAllDivisasRate"]),
    calcularRecibirSinPaypal(divisaRate, usd) {
      let total = usd.divide(1 - divisaRate / 100);
      const comision = total.subtract(usd);

      this.receiverFlag =
        total.lessThan(this.comision.montoMinimo) && !this.isNewUser;

      if (this.receiverFlag) {
        total = total.add(this.comision.comision);
      }

      this.receiveUsdOpe2 = total.toFormat("$0.00").replace(".", ",");
      this.receiveUsdOpe3 = comision.toFormat("$0.00").replace(".", ",");
      return { comision, total };
    },
    calcularRecibirConPaypal(divisaRate, usd) {
      const rate = parseFloat(this.rate.replace(",", "."), 10);
      const rateUSD = this.rateUSD.convertPrecision(10);
      const receive = usd.convertPrecision(10);
      let totalMasPaypal = null;

      if (this.origin === "Paypal") {
        let total = receive.divide(1 - divisaRate / 100);

        this.receiverFlag =
          total.lessThan(this.comision.montoMinimo) && !this.isNewUser;

        if (this.receiverFlag) {
          total = total.add(this.comision.comision);
        }
        const comision = total.subtract(receive);

        totalMasPaypal = total
          .add(rateUSD)
          .divide(1 - rate / 100)
          .convertPrecision(2);

        this.receiveUsdOpe2 = totalMasPaypal
          .toFormat("$0.00")
          .replace(".", ",");

        this.receiveUsdOpe3 = comision.toFormat("$0.00").replace(".", ",");
        this.receiveUsdOpe4 = totalMasPaypal
          .subtract(total)
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        totalMasPaypal = receive.add(rateUSD).divide(1 - rate / 100);

        let total = totalMasPaypal.divide(1 - divisaRate / 100);
        const comision = total.subtract(totalMasPaypal);

        this.receiverFlag =
          total.lessThan(this.comision.montoMinimo) && !this.isNewUser;

        if (this.receiverFlag) {
          total = total.add(this.comision.comision);
        }

        this.receiveUsdOpe2 = total.toFormat("$0.00").replace(".", ",");
        this.receiveUsdOpe3 = comision.toFormat("$0.00").replace(".", ",");
        this.receiveUsdOpe4 = totalMasPaypal
          .convertPrecision(2)
          .subtract(usd.convertPrecision(2))
          .toFormat("$0.00")
          .replace(".", ",");
      }
    },
    calcularEnvioSinPaypal(divisaRate, usd) {
      this.sendFlag = usd.lessThan(this.comision.montoMinimo) && !this.isNewUser;

      if (this.sendFlag) {
        usd = usd.subtract(this.comision.comision);
      }

      usd = usd.convertPrecision(10);

      const comision = usd.multiply(divisaRate).divide(100);
      let total = usd.subtract(comision);

      this.sendUsdOpe2 = total.toFormat("$0.00").replace(".", ",");
      this.sendUsdOpe3 = comision.toFormat("$0.00").replace(".", ",");
      return total;
    },
    calcularEnvioConPaypal(divisaRate, usd) {
      usd = usd.convertPrecision(10);
      const rate = parseFloat(this.rate.replace(",", "."), 10);
      const rateUSD = this.rateUSD.convertPrecision(10);

      if (this.origin === "Paypal") {
        const comisionPaypal = usd.multiply(rate / 100).add(rateUSD);
        let totalPaypal = usd.subtract(comisionPaypal);

        this.sendFlag =
          totalPaypal.lessThan(this.comision.montoMinimo) && !this.isNewUser;

        if (this.sendFlag) {
          totalPaypal = totalPaypal.subtract(this.comision.comision);
        }

        const totalSwap = totalPaypal.subtract(
          totalPaypal.multiply(divisaRate / 100)
        );

        const comisionSwap = totalPaypal.subtract(totalSwap);

        this.sendUsdOpe2 = totalSwap.toFormat("$0.00").replace(".", ",");
        this.sendUsdOpe3 = comisionSwap.toFormat("$0.00").replace(".", ",");
        this.sendUsdOpe4 = comisionPaypal.toFormat("$0.00").replace(".", ",");
      } else {
        this.sendFlag =
          usd.lessThan(this.comision.montoMinimo) && !this.isNewUser;

        if (this.sendFlag) {
          usd = usd.subtract(this.comision.comision);
        }

        const comisionSwap = usd.multiply(divisaRate / 100);
        let totalSwap = usd.subtract(comisionSwap);

        const totalPaypal = totalSwap.subtract(
          totalSwap.multiply(rate / 100).add(rateUSD)
        );

        const comisionPaypal = totalSwap.subtract(totalPaypal);

        this.sendUsdOpe2 = totalPaypal.toFormat("$0.00").replace(".", ",");
        this.sendUsdOpe3 = comisionSwap.toFormat("$0.00").replace(".", ",");
        this.sendUsdOpe4 = comisionPaypal.toFormat("$0.00").replace(".", ",");
      }
    },
    paypalPaypalReceive(receive) {
      const { vex, client } = this;
      const divisaRate = parseFloat(
        this.divisasRate.paypal.paypal.replace(",", "."),
        10
      );

      // Para Recibir
      const firstPaypalTotal = receive
        .add(client.rateUSD)
        .divide(1 - client.rate / 100);
      const firstPaypalFee = firstPaypalTotal.subtract(receive);

      const vexTotalWithFee = firstPaypalTotal.divide(1 - divisaRate / 100);
      const comisionVex = vexTotalWithFee.subtract(firstPaypalTotal);

      const secondPaypalTotal = vexTotalWithFee
        .add(vex.rateUSD)
        .divide(1 - vex.rate / 100);
      const secondPaypalFee = secondPaypalTotal.subtract(vexTotalWithFee);

      const totalPaypalFee = firstPaypalFee.add(secondPaypalFee);

      this.receiveUsdOpe2 = secondPaypalTotal
        .toFormat("$0.00")
        .replace(".", ",");
      this.receiveUsdOpe3 = comisionVex.toFormat("$0.00").replace(".", ",");
      this.receiveUsdOpe4 = totalPaypalFee.toFormat("$0.00").replace(".", ",");
    },
    paypalPaypalSend(send) {
      const { vex, client } = this;
      const divisaRate = parseFloat(
        this.divisasRate.paypal.paypal.replace(",", "."),
        10
      );

      // Para Enviar
      const firstPaypalTotal = send.subtract(
        send.multiply(vex.rate / 100).add(vex.rateUSD)
      );
      const firstPaypalFee = send.multiply(vex.rate / 100).add(vex.rateUSD);
      const vexTotalWithFee = firstPaypalTotal.multiply(1 - divisaRate / 100);
      const comisionVex = firstPaypalTotal.subtract(vexTotalWithFee);
      const secondPaypalTotal = vexTotalWithFee.subtract(
        vexTotalWithFee.multiply(client.rate / 100).add(client.rateUSD)
      );
      const secondPaypalFee = vexTotalWithFee
        .multiply(client.rate / 100)
        .add(client.rateUSD);
      const totalPaypalFee = firstPaypalFee.add(secondPaypalFee);

      this.sendUsdOpe2 = secondPaypalTotal.toFormat("$0.00").replace(".", ",");
      this.sendUsdOpe3 = comisionVex.toFormat("$0.00").replace(".", ",");
      this.sendUsdOpe4 = totalPaypalFee.toFormat("$0.00").replace(".", ",");
    },
    formatterRate(value) {
      let result = "0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          amount = amount.multiply(-1);
          result = amount.toUnit().toFixed(2).replace(".", ",");
        }
        if (amount.greaterThan(Dinero({ amount: 10000 }))) {
          result = "100,00";
        } else {
          result = amount.toUnit().toFixed(2).replace(".", ",");
        }
        this.rate = result;
        if (this.divisaRate && this.divisaRate !== "N/A") {
          const receive = Dinero({
            amount: parseInt(
              this.receiveUsdOpe1.replace(/[,.$€Bs\s]/g, ""),
              10
            ),
            currency: "USD",
            precision: 2,
          });
          const send = Dinero({
            amount: parseInt(this.sendUsdOpe1.replace(/[,.$€Bs\s]/g, ""), 10),
            currency: "USD",
            precision: 2,
          });
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularRecibirConPaypal(rate, receive);
          this.calcularEnvioConPaypal(rate, send);
        }
      }
      return result;
    },
    formatterRateUSD(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          amount = amount.multiply(-1);
          result = amount.toFormat("$0.00").replace(".", ",");
        } else {
          result = amount.toFormat("$0.00").replace(".", ",");
        }
        this.rateUSD = amount;
        this.rateFixed = result;
        if (this.divisaRate && this.divisaRate !== "N/A") {
          const receive = Dinero({
            amount: parseInt(
              this.receiveUsdOpe1.replace(/[,.$€Bs\s]/g, ""),
              10
            ),
            currency: "USD",
            precision: 2,
          });
          const send = Dinero({
            amount: parseInt(this.sendUsdOpe1.replace(/[,.$€Bs\s]/g, ""), 10),
            currency: "USD",
            precision: 2,
          });
          const rate = parseFloat(
            this.divisaRate.replace(",", ".").replace("%", "")
          );
          this.calcularRecibirConPaypal(rate, receive);
          this.calcularEnvioConPaypal(rate, send);
        }
      }
      return result;
    },
    formatterReceiver(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          amount = amount.multiply(-1);
        }
        result = amount.toFormat("$0.00").replace(".", ",");
        if (
          this.receiveUsdOpe1 !== "$0,00" &&
          this.divisaRate &&
          this.divisaRate !== "N/A"
        ) {
          if (this.origin === "Paypal" && this.destination === "Paypal") {
            this.paypalPaypalReceive(amount);
          } else if (
            this.destination !== "Paypal" &&
            this.origin !== "Paypal"
          ) {
            const rate = parseFloat(
              this.divisaRate.replace(",", ".").replace("%", "")
            );
            this.calcularRecibirSinPaypal(rate, amount);
          } else if (this.receiveUsdOpe1 !== "$0,00") {
            const rate = parseFloat(
              this.divisaRate.replace(",", ".").replace("%", "")
            );
            this.calcularRecibirConPaypal(rate, amount);
          }
        }
      }
      return result;
    },
    formatterSend(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          result = amount.multiply(-1).toFormat("$0.00").replace(".", ",");
        }
        result = amount.toFormat("$0.00").replace(".", ",");
        if (
          this.sendUsdOpe1 !== "$0,00" &&
          this.divisaRate &&
          this.divisaRate !== "N/A"
        ) {
          if (this.origin === "Paypal" && this.destination === "Paypal") {
            this.paypalPaypalSend(amount);
          } else if (
            this.destination !== "Paypal" &&
            this.origin !== "Paypal"
          ) {
            const rate = parseFloat(
              this.divisaRate.replace(",", ".").replace("%", "")
            );
            this.calcularEnvioSinPaypal(rate, amount);
          } else {
            const rate = parseFloat(
              this.divisaRate.replace(",", ".").replace("%", "")
            );
            this.calcularEnvioConPaypal(rate, amount);
          }
        }
      }
      return result;
    },
    toUpperCase: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    reset() {
      this.receiveUsdOpe1 = "$0,00";
      this.receiveUsdOpe2 = "$0,00";
      this.receiveUsdOpe3 = "$0,00";
      this.receiveUsdOpe4 = "$0,00";
      this.sendUsdOpe1 = "$0,00";
      this.sendUsdOpe2 = "$0,00";
      this.sendUsdOpe3 = "$0,00";
      this.sendUsdOpe4 = "$0,00";
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  max-width: 700px;
  margin: 0 auto;
}

.append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  // background-color: #e9ecef;
  border: 1px solid #eee;
  // border-radius: 0.25rem;
}
</style>
