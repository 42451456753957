<template>
  <!-- Start ML Feedback Area -->
  <section>
    <div class="container">
      <div class="section-title mb-0">
        <h2>{{ $t("feedback.title") }}</h2>
        <div class="bar"></div>
      </div>

      <!-- Embedded Instagram -->
      <!-- <div class="row justify-content-center mb-5">
        <div
          class="col-md-12 col-lg-8"
          :style="[
            width >= 1068 ? { 'max-width': '100%' } : { 'max-width': '75%' },
          ]"
        >
          <embed-instagram />
        </div>
      </div> -->

      <!-- Positive Comments Carousel -->
      <div class="row justify-content-around align-items-center">
        <div class="col-sm-12 col-lg-8">
          <b-carousel
            id="carousel-instagram-comments"
            v-model="slide"
            :interval="5000"
            controls
            img-width="1024"
            :img-height="imgHeight"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <b-carousel-slide
              v-for="comment in commentsData"
              :key="comment.hashtag"
              img-blank
              img-alt="Carousel Background"
              :indicators="false"
            >
              <div
                :class="[
                  width >= 575
                    ? 'row justify-content-around align-items-center'
                    : 'row justify-content-around align-items-center comment-container-responsive',
                ]"
              >
                <div class="col-sm-4">
                  <img
                    :class="[
                      width >= 1068
                        ? 'profile-photo'
                        : 'profile-photo-responsive',
                    ]"
                    :src="
                      require(`../../../assets/img/feedback/profile-pictures/${comment.hashtag}.png`)
                    "
                    :alt="`${comment.hashtag} instagram profile picture`"
                  />
                </div>
                <div class="col-sm-8">
                  <p :class="[width <= 575 ? 'text-responsive' : '']">
                    <strong>{{ comment.hashtag }}</strong>
                  </p>
                  <p
                    :class="[
                      width <= 765 ? 'text-responsive text-info' : 'text-info',
                    ]"
                  >
                    {{ comment.text }}
                  </p>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>

    <!--
    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    -->
  </section>
  <!-- End ML Feedback Area -->
</template>

<script>
// import EmbedInstagram from "./EmbedInstagram";
import data from "../../../constants/instagram-comments.json";
const { comments } = data;

export default {
  name: "Feedback",
  components: {
    // EmbedInstagram,
  },
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      slide: 0,
      sliding: null,
      commentsData: comments,
      imgHeight: 0,
    };
  },
  created() {
    this.imgHeight = window.innerWidth >= 575 ? 280 : 700;
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.imgHeight = this.width >= 575 ? 280 : 700;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
}
.comment-container-responsive {
  margin-bottom: 15%;
}
.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profile-photo-responsive {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.text-responsive {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>