import mutations from "./mutations";
import actions from "./actions";

const state = {
    arrayOperations: [],
    optionsOperations: [],
    ratesData: {},
    isNewUser: false,
    comisionFlag: false,
    result: "$0,00",
    resultSinComision: "$0,00",
    isMixesEnv: false,
    residuoEnBsS: "Bs. 0,00",
    toSend: null,
    sendFlag: true,
};
const getters = {
    getArrayOperations: ({ arrayOperations }) => arrayOperations,
    getOptionsOperations: ({ optionsOperations }) => optionsOperations,
    getRateData: ({ ratesData }) => ratesData,
    getNewUserFlag: ({ isNewUser }) => isNewUser,
    getResult: ({ result }) => result,
    getComisionFlag: ({ comisionFlag }) => comisionFlag,
    getResultSinComision: ({ resultSinComision }) => resultSinComision,
    getIsMixesEnv: ({ isMixesEnv }) => isMixesEnv,
    getResiduo: ({ residuoEnBsS }) => residuoEnBsS,
    getToSend: ({ toSend }) => toSend,
};

export default {
    state,
    getters,
    mutations,
    actions
};