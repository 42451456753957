<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="row justify-content-between align-content-center">
        <div class="col-sm-4 sub-container">
          {{ $t("calculator-landing.type") }}:
          <b-form-select v-model="selected" :options="options" plain />
        </div>
        <div class="col-sm-7">
          <div class="sub-container">
            <p class="rates-title">
              {{ $t("calculator-landing.rate") }}: 1
              {{ selected === "Euro" ? "EUR" : "USD" }} = {{ rateBs }}
            </p>
            <p v-if="selected === 'Euro'" class="rates">
              {{ $t("calculator-landing.payingWith") }}
              <strong>{{ $t("calculator-landing.eur-mode") }}</strong>
            </p>
            <p v-else class="rates">
              {{ $t("calculator-landing.payingWith") }}
              <strong>{{ selected }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="row justify-content-center align-items-center mb-3">
        <label for="isNewUser2Toggle" class="mb-0">
          {{ $t("calculator-landing.isNewUser") }}
        </label>
        <b-form-checkbox
          class="ml-2 mr-n2"
          id="isNewUser2Toggle"
          v-model="isNewUser"
          name="is-new-user-toggle"
          switch
          size="md"
        >
        </b-form-checkbox>
      </div>
      <p>
        {{
          $t("calculator-landing.isNewUserDescription", {
            cobroMinimo: Dinero({
              amount: comision,
              currency: selected === "Euro" ? "EUR" : "USD",
            })
              .toFormat("$0.00")
              .replace(".", ","),
            montoMinimo: Dinero({
              amount: montoMinimo,
              currency: selected === "Euro" ? "EUR" : "USD",
            })
              .toFormat("$0.00")
              .replace(".", ","),
          })
        }}
      </p>
    </div>
    <div class="col-sm-12 sub-container-focus">
      <div
        class="row justify-content-md-between justify-content-sm-center align-items-center"
      >
        <div class="col-sm-12 col-md-6">
          <p v-if="swapFlag" class="mb-0 sub-container-focus-text">
            <strong v-if="selected !== 'Euro'">
              {{ $t("calculator-landing.send") + " " + selected }}
            </strong>
            <strong v-else class="mb-0 sub-container-focus-text">
              {{
                $t("calculator-landing.send") +
                " " +
                $t("calculator-landing.sepa")
              }}
            </strong>
          </p>
          <p v-else class="sub-container-focus-text mb-0">
            <strong>{{ $t("calculator-landing.receive-ve") }}</strong>
          </p>
        </div>
        <div class="col-sm-12 col-md-6">
          <div
            class="row form-group mb-0 justify-content-end align-items-center"
          >
            <div class="col-sm-6" v-if="!swapFlag">
              <div v-if="selected === 'Paypal'" class="text-right">
                <div v-if="!cobroMinimo">
                  <span
                    v-if="afterPaypal !== 0"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ afterPaypal }}
                  </span>
                  <span
                    v-if="afterPaypal !== 0"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ "x " + rateBs + " = " }}
                  </span>
                </div>
                <div v-else>
                  <span
                    v-if="afterPaypal !== 0"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ amountMinusCePaypal }}
                  </span>
                  <span
                    v-if="afterPaypal !== 0"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ "x " + rateBs + " = " }}
                  </span>
                </div>
              </div>
              <div v-else class="text-right">
                <div v-if="!cobroMinimo">
                  <span
                    v-if="montoAbajo !== '$0,00'"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ montoAbajo }}
                  </span>
                  <span
                    v-if="montoAbajo !== '$0,00'"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ "x " + rateBs + " = " }}
                  </span>
                </div>
                <div v-else>
                  <span
                    v-if="montoAbajo !== '$0,00'"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ amountMinusCe }}
                  </span>
                  <span
                    v-if="montoAbajo !== '$0,00'"
                    class="sub-container-focus-text mb-0"
                  >
                    {{ "x " + rateBs + " = " }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <b-form-input
                v-model="montoArriba"
                type="text"
                class="form-control text-right"
                :formatter="formatterArriba"
                @click="cursorHandler"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="d-flex justify-content-between align-items-center">
        <div class="row align-items-center">
          <div v-if="selected === 'Paypal'" class="col-6 mb-3">
            <p v-if="swapFlag" class="info-text mb-0">
              {{ $t("calculator-landing.paypal-receive") }}:
            </p>
            <p v-else class="info-text mb-0">
              {{ $t("calculator-landing.paypal-send") }}:
            </p>
          </div>
          <div v-if="selected === 'Paypal'" class="col-4 mb-3">
            <p class="info-text mb-0">{{ afterPaypal }}</p>
          </div>
          <div v-if="selected === 'Paypal'" class="col-6 mb-3">
            <p class="info-text mb-0">
              {{ $t("calculator-landing.paypal-tax") }}
            </p>
          </div>
          <div v-if="selected === 'Paypal'" class="col-4 mb-3">
            <p class="info-text mb-0">{{ paypalTax }}</p>
          </div>
          <!-- div v-if="cobroMinimo" class="col-6 mb-3"-->
          <div class="col-6 mb-3">
            <p class="info-text mb-0">
              {{
                $t("calculator-landing.ce", {
                  montoMinimo: Dinero({
                    amount: montoMinimo,
                    currency: selected === "Euro" ? "EUR" : "USD",
                  })
                    .toFormat("$0.00")
                    .replace(".", ","),
                })
              }}
            </p>
          </div>
          <!-- div v-if="cobroMinimo" class="col-4 mb-3" -->
          <div class="col-4 mb-3">
            <p class="info-text mb-0">
              {{ Dinero({
              amount: cobroMinimo ? comision : 0,
              currency: selected === "Euro" ? "EUR" : "USD",
            })
              .toFormat("$0.00")
              .replace(".", ","), }}
            </p>
          </div>
        </div>
        <img
          v-if="swapFlag"
          class="swapIcon"
          src="../../../../../src/assets/img/calculators/swap-1.png"
          @click="changeSwap"
        />
        <img
          v-else
          class="swapIcon"
          src="../../../../../src/assets/img/calculators/swap-2.png"
          @click="changeSwap"
        />
      </div>
    </div>
    <div class="col-sm-12 sub-container-focus">
      <div class="d-flex justify-content-between align-items-center">
        <div class="sub-container-focus-text-container">
          <p v-if="swapFlag" class="sub-container-focus-text mb-0">
            <strong> {{ $t("calculator-landing.receive") }} </strong>
          </p>
          <p v-else class="sub-container-focus-text mb-0">
            <strong v-if="selected !== 'Euro'">
              {{ $t("calculator-landing.receive-swap") + " " + selected }}
            </strong>
            <strong v-else>
              {{
                $t("calculator-landing.receive-swap") +
                " " +
                $t("calculator-landing.sepa")
              }}
            </strong>
          </p>
        </div>

        <div class="d-flex p-2 justify-content-end align-items-center">
          <div v-if="swapFlag">
            <div v-if="selected === 'Paypal'" class="text-right">
              <div v-if="!cobroMinimo">
                <span
                  v-if="afterPaypal !== 0"
                  class="sub-container-focus-text mb-0"
                >
                  {{ afterPaypal }}
                </span>
                <span
                  v-if="afterPaypal !== 0"
                  class="sub-container-focus-text mb-0"
                >
                  {{ "x " + rateBs + " = " }}
                </span>
              </div>
              <div v-else>
                <span
                  v-if="afterPaypal !== 0"
                  class="sub-container-focus-text mb-0"
                >
                  {{ amountMinusCePaypal }}
                </span>
                <span
                  v-if="afterPaypal !== 0"
                  class="sub-container-focus-text mb-0"
                >
                  {{ "x " + rateBs + " = " }}
                </span>
              </div>
            </div>
            <div v-else class="text-right">
              <div v-if="!cobroMinimo">
                <span
                  v-if="montoArriba !== '$0,00'"
                  class="sub-container-focus-text mb-0"
                >
                  {{ montoArriba }}
                </span>
                <span
                  v-if="montoArriba !== '$0,00'"
                  class="sub-container-focus-text mb-0"
                >
                  {{ "x " + rateBs + " = " }}
                </span>
              </div>
              <div v-else>
                <span
                  v-if="montoArriba !== '$0,00'"
                  class="sub-container-focus-text mb-0"
                >
                  {{ amountMinusCe }}
                </span>
                <span
                  v-if="montoArriba !== '$0,00'"
                  class="sub-container-focus-text mb-0"
                >
                  {{ "x " + rateBs + " = " }}
                </span>
              </div>
            </div>
          </div>
          <div>
            <span class="ml-1 text-white text-right">
              {{ montoAbajo }}
            </span>
            <!-- <b-form-input
          v-model="montoAbajo"
          type="text"
          class="form-control text-right"
          :formatter="formatterAbajo"
          @click="cursorHandler"
        /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dinero from "dinero.js";
import { getRates } from "../../../../constants/config";
const { helpers } = require("vuelidate/lib/validators");
const notAlpha = helpers.regex("notAlpha", /[^A-Za-z]+$/);

export default {
  props: {
    rateData: {
      type: Object,
      default: () => ({})
    },
  },
  name: "ModsToBs",
  data() {
    return {
      montoArriba: "$0,00",
      montoAbajo: "Bs. 0,00",
      swapFlag: true, // true === USD false === VES
      selected: "Paypal",
      options: [],
      client: {},
      vex: {},
      isNewUser: false,
      tasasOptions: {},
      cobroMinimo: false,
      amountMinusCe: "$0,00",
      amountMinusCePaypal: "$0,00",
      afterPaypal: 0,
      paypalTax: 0,
      rateBs: "Bs. 0,00",
      comision: 0,
      montoMinimo: 0
    };
  },
  async created() {
    const { divisasData, client, rate, rateUSD, eur, paypal, usd, comision } =
      await this.getRates();

    this.comision = comision.comision;
    this.montoMinimo = comision.montoMinimo;
    this.tasasOptions = {
      eur: parseFloat(eur.replace(",", "."), 10),
      paypal: parseFloat(paypal.replace(",", "."), 10),
      usd: parseFloat(usd.replace(",", "."), 10),
    };
    this.rateBs =
      "Bs. " + this.tasasOptions.paypal.toString().replace(".", ",");
    this.client = {
      rate: parseFloat(client.rate.replace(",", "."), 10),
      rateUSD: Dinero({
        amount: parseInt(client.rateUSD.replace(/[,.$€\s]/g, ""), 10),
        currency: "USD",
        precision: 2,
      }),
    };
    this.vex = {
      rate: parseFloat(rate.replace(",", "."), 10),
      rateUSD: Dinero({
        amount: parseInt(rateUSD.replace(/[,.$€\s]/g, ""), 10),
        currency: "USD",
        precision: 2,
      }),
    };
    this.options = Object.keys(divisasData).reduce((acc, ele) => {
      if (ele === "zinli") {
        return acc;
      }
      if (ele !== "usdt") {
        acc.push(this.toUpperCase(ele));
      } else {
        acc.push(ele.toUpperCase());
      }
      return acc;
    }, []);

    if (this.tasasOptions.eur > 0) {
      this.options.push("Euro");
    }
    if (this.tasasOptions.paypal > 0 && !this.options.some((x) => x === "Paypal")) {
      this.options.push("Paypal");
    }

    this.selected = "Zelle";

  },
  watch: {
    isNewUser() {
      let moneda = this.selected === "Euro" ? "EUR" : "USD";
      const result = moneda === "EUR" ? "€0,00" : "$0,00";

      if (
        this.montoArriba !== "$0,00" ||
        this.montoArriba !== "Bs. 0,00" ||
        this.montoArriba !== "€0,00"
      ) {
        let amount = parseInt(this.montoArriba.replace(/[,.$€BsVES\s]/g, ""));

        amount = Dinero({
          amount,
          currency: this.swapFlag ? moneda : "VES",
        });
        if (
          amount.greaterThan(
            Dinero({
              amount: 0,
              currency: this.swapFlag ? moneda : "VES",
            })
          )
        ) {
          this.calcularMontoDeArribaHaciaAbajo(amount);
        } else {
          this.montoArriba = this.swapFlag ? result : "Bs. 0,00";
          this.montoAbajo = !this.swapFlag ? result : "Bs. 0,00";
        }
      }
    },
    selected(val) {
      const moneda = val === "Euro" ? "EUR" : "USD";
      let amount = parseInt(this.montoArriba.replace(/[,.$€BsVES\s]/g, ""));
      amount = Dinero({
        amount,
        currency: this.swapFlag ? moneda : "VES",
        precision: 2,
      });
      switch (val) {
        case "Euro":
          this.rateBs =
            "Bs. " + this.tasasOptions.eur.toString().replace(".", ",");
          break;
        case "Paypal":
          this.rateBs =
            "Bs. " + this.tasasOptions.paypal.toString().replace(".", ",");
          break;
        default:
          this.rateBs =
            "Bs. " + this.tasasOptions.usd.toString().replace(".", ",");
          break;
      }

      if (
        amount.greaterThan(
          Dinero({
            amount: 0,
            currency: this.swapFlag ? moneda : "VES",
            precision: 2,
          })
        )
      ) {
        this.montoArriba = this.swapFlag
          ? amount.toFormat("$0.00").replace(".", ",")
          : this.montoArriba;
        this.calcularMontoDeArribaHaciaAbajo(amount);
      } else {
        const result = moneda === "EUR" ? "€0,00" : "$0,00";
        this.montoArriba = this.swapFlag ? result : "Bs. 0,00";
        this.montoAbajo = !this.swapFlag ? result : "Bs. 0,00";
      }
    },
    swapFlag(/* val */) {
      // let amount = parseInt(this.montoArriba.replace(/[,.$€BsVES\s]/g, ""));
      let monedaSeleccionada = this.selected === "Euro" ? "EUR" : "USD";
      const result = monedaSeleccionada === "EUR" ? "€0,00" : "$0,00";
      this.montoArriba = this.swapFlag ? result : "Bs. 0,00";
      this.montoAbajo = !this.swapFlag ? result : "Bs. 0,00";
      this.cobroMinimo = false;
      this.afterPaypal = "$0,00";
      this.paypalTax = "$0,00";
      /*       let rateVES = 0.0;

      switch (this.selected) {
        case "Euro":
          rateVES = this.tasasOptions.eur;
          break;
        case "Paypal":
          rateVES = this.tasasOptions.paypal;
          break;
        default:
          rateVES = this.tasasOptions.usd;
          break;
      }

      const moneda = val ? monedaSeleccionada : "VES";
      amount = Dinero({
        amount,
        currency: moneda,
        precision: 2,
      });
      if (
        amount.greaterThan(
          Dinero({
            amount: 0,
            currency: moneda,
            precision: 2,
          })
        )
      ) {
        if (!val) {
          amount
            .convert("VES", {
              endpoint: new Promise((resolve) =>
                resolve({
                  rates: {
                    VES: rateVES,
                  },
                })
              ),
            })
            .then((salida) => {
              this.montoArriba = salida
                .toFormat("$0.00")
                .replace(".", ",")
                .replace("VES", "Bs. ");
              this.calcularMontoDeArribaHaciaAbajo(salida);
            });
        } else {
          amount
            .convert(moneda, {
              endpoint: new Promise((resolve) =>
                resolve({
                  rates: {
                    [moneda]: 1 / rateVES,
                  },
                })
              ),
            })
            .then((salida) => {
              this.montoArriba = salida
                .toFormat("$0.00")
                .replace(".", ",")
                .replace("VES", "Bs. ");
              this.calcularMontoDeArribaHaciaAbajo(salida);
            });
        }
      } else {
        const result = monedaSeleccionada === "EUR" ? "€0,00" : "$0,00";
        this.montoArriba = this.swapFlag ? result : "Bs. 0,00";
        this.montoAbajo = !this.swapFlag ? result : "Bs. 0,00";
      }*/
    },
  },
  methods: {
    Dinero,
    changeSwap() {
      this.swapFlag = !this.swapFlag;
    },
    cursorHandler(event) {
      event.target.selectionStart = 10000;
      event.target.selectionEnd = 10000;
      return event;
    },
    toUpperCase: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    getRates,
    formatterArriba(value) {
      let monedaSeleccionada = this.selected === "Euro" ? "EUR" : "USD";
      let result = monedaSeleccionada === "Euro" ? "€0,00" : "$0,00";

      result = this.swapFlag ? result : "Bs. 0,00";

      const moneda = this.swapFlag ? monedaSeleccionada : "VES";

      if (!value) {
        return result;
      }
      let amount = parseInt(value.replace(/[,.$€BsVES\s]/g, ""));
      if (!notAlpha(value) || Number.isNaN(amount)) {
        return result;
      }

      amount = Dinero({
        amount,
        currency: moneda,
        precision: 2,
      });

      if (!this.swapFlag) {
        result = amount
          .toFormat("$0.00")
          .replace(".", ",")
          .replace("VES", "Bs.");
      } else {
        result = amount.toFormat("$0.00").replace(".", ",");
      }

      this.calcularMontoDeArribaHaciaAbajo(amount);

      return result;
    },
    calcularMontoDeArribaHaciaAbajo(monto) {
      let rateVES = 0.0;
      let moneda = "";

      // let result = Dinero({ amount: 0, currency: "USD" });

      switch (this.selected) {
        case "Euro":
          rateVES = this.tasasOptions.eur;
          moneda = "EUR";
          break;
        case "Paypal":
          rateVES = this.tasasOptions.paypal;
          moneda = "USD";
          break;
        default:
          moneda = "USD";
          rateVES = this.tasasOptions.usd;
          break;
      }
      if (this.swapFlag) {
        if (this.selected === "Paypal") {
          const rate = this.vex.rate;
          const rateUSD = this.vex.rateUSD;
          this.calcularDePaypalABolivares(
            monto,//.convertPrecision(15),
            rate,
            rateUSD,//.convertPrecision(15),
            rateVES
          ).then((salida) => {
            this.montoAbajo = salida
              .toFormat("$0.00")
              .replace(".", ",")
              .replace("VES", "Bs. ");
          });
        } else {
          this.calcularDeMonedaABolivares(
            monto.convertPrecision(10),
            rateVES
          ).then((salida) => {
            this.montoAbajo = salida
              .toFormat("$0.00")
              .replace(".", ",")
              .replace("VES", "Bs. ");
          });
        }
      } else {
        if (this.selected === "Paypal") {
          const rate = this.vex.rate;
          const rateUSD = this.vex.rateUSD;
          this.calcularDeBolivaresAPaypal(
            monto.convertPrecision(10),
            rate,
            rateUSD.convertPrecision(10),
            rateVES
          ).then((salida) => {
            this.montoAbajo = salida.toFormat("$0.00").replace(".", ",");
          });
        } else {
          monto
            .convert(moneda, {
              endpoint: new Promise((resolve) =>
                resolve({
                  rates: {
                    [moneda]: 1 / rateVES,
                  },
                })
              ),
            })
            .then((salida) => {
              this.cobroMinimo =
                salida.lessThan(
                  Dinero({
                    amount: this.montoMinimo,
                    currency: salida.getCurrency(),
                  })
                ) && !this.isNewUser;

              if (this.cobroMinimo) {
                if (
                  salida.greaterThan(
                    Dinero({
                      amount: this.comision,
                      currency: salida.getCurrency(),
                    })
                  )
                )
                  this.amountMinusCe = salida
                    .toFormat("$0.00")
                    .replace(".", ",");
                else
                  this.amountMinusCe = Dinero({
                    amount: 0,
                    currency: salida.getCurrency(),
                  })
                    .toFormat("$0.00")
                    .replace(".", ",");

                salida = salida.add(
                  Dinero({
                    amount: this.comision,
                    currency: salida.getCurrency(),
                  })
                );
              }
              this.montoAbajo = salida.toFormat("$0.00").replace(".", ",");
            });
        }
      }
    },
    calcularDeBolivaresAPaypal(monto, rate, rateUSD, rateVES) {
      return monto
        .convert("USD", {
          endpoint: new Promise((resolve) =>
            resolve({
              rates: {
                USD: 1 / rateVES,
              },
            })
          ),
        })
        .then((result) => {
          this.cobroMinimo =
            result.lessThan(Dinero({ amount: this.montoMinimo })) &&
            !this.isNewUser;

          if (this.cobroMinimo) {
            this.amountMinusCePaypal = result
              .toFormat("$0.00")
              .replace(".", ",");
            result = result.add(Dinero({ amount: this.comision }));
          }

          const total = result.add(rateUSD).divide(1 - rate / 100);
          const comision = total.subtract(result);

          this.afterPaypal = result.toFormat("$0.00").replace(".", ",");
          this.paypalTax = comision.toFormat("$0.00").replace(".", ",");

          return total;
        });
    },
    calcularDePaypalABolivares(usd, rate, rateUSD, rateVES) {
      const comisionPaypal = usd.multiply(rate / 100, 'HALF_UP').add(rateUSD);
      let total = usd.subtract(comisionPaypal);

      // eslint-disable-next-line no-console
      console.log(usd.toFormat(), total.toUnit(), comisionPaypal.toUnit() ,Dinero({ amount: this.comision }).toFormat())

      this.afterPaypal = total.toFormat("$0.00").replace(".", ",");
      this.paypalTax = comisionPaypal.toFormat("$0.00").replace(".", ",");

      this.cobroMinimo =
        total.lessThan(Dinero({ amount: this.montoMinimo })) && !this.isNewUser;

      if (this.cobroMinimo) {
        total = total.subtract(Dinero({ amount: this.comision }));
        this.amountMinusCePaypal = total.toFormat("$0.00").replace(".", ",");
      }

      return Dinero({
        amount: parseInt(
          total.toFormat("$0.00").replace(/[,.$€BsVES\s]/g, ""), //+ "00000000",
          10
        ),
        currency: total.getCurrency(),
        //precision: 10,
      }).convert("VES", {
        endpoint: new Promise((resolve) =>
          resolve({
            rates: {
              VES: rateVES,
            },
          })
        ),
      });
    },
    calcularDeMonedaABolivares(monto, rateVES) {
      this.cobroMinimo =
        monto.lessThan(
          Dinero({ amount: this.montoMinimo, currency: monto.getCurrency() })
        ) && !this.isNewUser;

      if (this.cobroMinimo) {
        monto = monto.subtract(
          Dinero({ amount: this.comision, currency: monto.getCurrency() })
        );

        if (
          monto.greaterThan(
            Dinero({ amount: this.comision, currency: monto.getCurrency() })
          )
        )
          this.amountMinusCe = monto.toFormat("$0.00").replace(".", ",");
        else
          this.amountMinusCe = Dinero({
            amount: 0,
            currency: monto.getCurrency(),
          })
            .toFormat("$0.00")
            .replace(".", ",");
      }

      return Dinero({
        amount: parseInt(
          monto.toFormat("$0.00").replace(/[,.$€BsVES\s]/g, "") + "00000000",
          10
        ),
        currency: monto.getCurrency(),
        precision: 10,
      }).convert("VES", {
        endpoint: new Promise((resolve) =>
          resolve({
            rates: {
              VES: rateVES,
            },
          })
        ),
      });
    },
     /*     formatterAbajo(value) {
      let result = !this.swapFlag ? "$0,00" : "Bs.0,00";
      if (!value) {
        return result;
      }
      let amount = parseInt(value.replace(/[,.$€BsVES\s]/g, ""));

      // eslint-disable-next-line no-console
      console.log(amount);

      if (!notAlpha(value) || Number.isNaN(amount)) {
        return result;
      }

      amount = Dinero({
        amount,
        currency: !this.swapFlag ? "USD" : "VES",
        precision: 2,
      });

      if (this.swapFlag) {
        return amount.toFormat("$0.00").replace(".", ",").replace("VES", "Bs.");
      }

      if (amount.isNegative()) {
        result = amount.multiply(-1).toFormat("$0.00").replace(".", ",");
      } else {
        result = amount.toFormat("$0.00").replace(".", ",");
      }

      return result;
    } */
  },
};
</script>

<style scoped lang="scss">
p {
  line-height: 1.5 !important;
}
.rates-title {
  font-size: 16px;
  text-align: right !important;
  line-height: 1.3 !important;
  margin-bottom: 0 !important;
}
.rates {
  font-size: 12px;
  text-align: right !important;
  line-height: 1.3 !important;
  margin-bottom: 0 !important;
}
.sub-container {
  margin: 25px 0px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sub-container-focus {
  margin: 25px 0px;
  padding: 10px;
  background: #30735a;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sub-container-focus-text-container {
  width: 30%;
}
.sub-container-focus-text {
  color: #fff;
  font-size: 12px;
}
.form-sub-container {
  width: 40%;
}
.input {
  background-color: #30735a !important;
  color: #fff !important;
  border: none !important;
}
.info-text {
  font-size: 12px;
}
.swapIcon {
  cursor: pointer;
}
</style>
