<template>
  <!-- Start ML Main Banner -->
  <div class="ml-main-banner">
    <div class="container-fluid">
      <div class="separator" v-if="width >= 1068" />
      <div
        class="row justify-content-sm-center justify-content-md-between align-items-center"
      >
        <div class="col-xl-5 col-lg-12">
          <div class="ml-banner-content">
            <h1>{{ $t("banner.title") }}</h1>
            <ul class="subtitle-list">
              <li>{{ $t("banner.bullet-point-subtitle-1") }}</li>
              <li>{{ $t("banner.bullet-point-subtitle-2") }}</li>
              <li>{{ $t("banner.bullet-point-subtitle-3") }}</li>
              <li>{{ $t("banner.bullet-point-subtitle-4") }}</li>
            </ul>

            <div class="container">
              <!-- We buy -->
              <div class="row justify-content-center align-items-center my-2">
                <p class="mb-0 font-weight-bold">{{ $t("banner.we-buy") }}:</p>
              </div>
              <div class="row justify-content-center align-items-center my-2">
                <!-- USD rate indicator -->
                <div
                  v-if="usdRate !== '0'"
                  class="col-lg-4 col-md-12 col-sm-12 mb-2 mb-lg-0"
                >
                  <div class="card p-2">
                    <router-link to="/calculators"
                      ><img
                        class="mr-3"
                        src="../../../assets/img/rates/usd.png"
                        alt="usd-rate-image"
                      />Bs. {{ usdRate }}</router-link
                    >
                  </div>
                </div>

                <!-- EUR rate indicator -->
                <div
                  v-if="eurRate !== '0'"
                  class="col-lg-4 col-md-12 col-sm-12 mb-2 mb-lg-0"
                >
                  <div class="card p-2">
                    <router-link to="/calculators"
                      ><img
                        class="mr-3"
                        src="../../../assets/img/rates/eur.png"
                        alt="eur-rate-image"
                      />Bs. {{ eurRate }}</router-link
                    >
                  </div>
                </div>

                <!-- PayPal rate indicator -->
                <div
                  v-if="paypalRate !== '0'"
                  class="col-lg-4 col-md-12 col-sm-12 mb-2 mb-lg-0"
                >
                  <div class="card p-2">
                    <router-link to="/calculators"
                      ><img
                        class="mr-3"
                        src="../../../assets/img/rates/paypal.png"
                        alt="paypal-rate-image"
                      />Bs. {{ paypalRate }}</router-link
                    >
                  </div>
                </div>
              </div>

              <!-- We sell -->
              <!-- <div class="row justify-content-center align-items-center my-2">
                <p class="mb-0 font-weight-bold">{{ $t("banner.we-sell") }}:</p>
              </div>
              <div class="row justify-content-center align-items-center mt-2"> -->
              <!-- USDVEX rate indicator -->
              <!-- <div
                  v-if="usdVEXRate !== '0'"
                  class="col-lg-4 col-md-12 col-sm-12 mb-3"
                >
                  <div class="card p-2">
                    <router-link class="no-wrap" to="/calculators">
                      <img
                        class="mr-3 usdVexRate"
                        src="../../../assets/img/rates/usdvex.png"
                        alt="usdvex-rate-image"
                      />
                      Bs. {{ usdVEXRate }}</router-link
                    >
                  </div>
                </div>
              </div> -->
            </div>
            <div class="text-center">
              <a
                :href="whatsAppUrl"
                target="_blank"
                class="btn btn-secondary mt-4 ml-1"
                >{{ $t("banner.contact-us") }}</a
              >
            </div>
            <div class="mt-3">
              <p>{{ $t("banner.subtitle-1") }}</p>
              <p>{{ $t("banner.subtitle-2") }}</p>
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-12">
          <div class="ml-banner-single-image">
            <!-- <img
              src="../../../assets/img/landing-banner-image/banner-img.png"
              alt="image"
            /> -->
            <Calculator :rateData="rateData" />
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape6.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    -->
  </div>
  <!-- End ML Main Banner -->
</template>

<script>
import { getRates } from "../../../constants/config";
import Calculator from "./Calculator";
export default {
  components: { Calculator },
  name: "Banner",
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      rateData: {},
      usdRate: "",
      eurRate: "",
      paypalRate: "",
      whatsAppUrl: `https://api.whatsapp.com/message/Q7W2DQUKEJNMO1?autoload=1&app_absent=0`,
    };
  },
  async created() {
    const data = await this.getRates();
    const { usd, eur, paypal, usdVEX } = data;
    this.rateData = data;
    this.usdRate = usd;
    this.eurRate = eur;
    this.paypalRate = paypal;
    this.usdVEXRate = usdVEX;
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getRates,
    /* playing() {
      // eslint-disable-next-line no-console
      console.log("o/ we are watching!!!");
    }, */
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped lang="scss">
h5 {
  color: #5f5f5f;
}
div.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  font-size: 1.1rem;
  cursor: pointer;
}
.separator {
  display: block;
  margin-top: 80px;
}
.subtitle-list {
  font-size: 16px;
  line-height: 2rem;
}
.disabled {
  color: #5f5f5f;
}
.usdVexRate {
  max-height: 28px;
}
</style>