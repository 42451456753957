<template>
  <!-- Start Mission Partner Area -->
  <section>
    <div v-if="width >= 1068" class="d-flex justify-content-center bg-mission">
      <div class="wow fadeInLeft mt-5" v-wow data-wow-delay="2s">
        <img src="../../../assets/img/mission/1.svg" alt="image" />
      </div>
      <div class="section-title mb-0">
        <span class="sub-title mt-3">{{ $t("mission.title") }}</span>
        <h4>{{ $t("mission.body-1") }}</h4>

        <h4 class="font-weight-bold">
          {{ $t("mission.body-2") }}
        </h4>
      </div>
      <div class="wow fadeInRight mt-5" v-wow data-wow-delay="2s">
        <img
          class="the-girl"
          src="../../../assets/img/mission/2.png"
          alt="image"
        />
      </div>
    </div>

    <div v-else class="d-flex flex-column justify-content-center bg-mission">
      <div class="section-title mb-0">
        <span class="sub-title mt-3">{{ $t("mission.title") }}</span>
        <h5 class="mx-3">{{ $t("mission.body-1") }}</h5>
        <h5 class="font-weight-bold mx-3">{{ $t("mission.body-2") }}</h5>
      </div>
      <div class="d-flex justify-content-around">
        <div class="wow fadeInLeft" v-wow data-wow-delay="2s">
          <img src="../../../assets/img/mission/1.svg" alt="image" />
        </div>
        <div class="wow fadeInRight" v-wow data-wow-delay="2s">
          <img
            class="the-girl"
            src="../../../assets/img/mission/2.png"
            alt="image"
          />
        </div>
      </div>
    </div>
  </section>
  <!-- End Mission Partner Area -->
</template>

<script>
export default {
  name: "Mission",
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
h4,
h5 {
  text-align: center;
  color: white;
}
.sub-title {
  color: #01c09e;
  background-color: white;
}
.bg-mission {
  background-color: #00be9c;
  margin-bottom: 60px;
}
.the-girl {
  width: 110px;
}
</style>