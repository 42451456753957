<template>
  <div class="mb-4">
    <div class="calculator-container p-5">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 sub-container">
          <span class="sub-container-focus-text"
            >{{ $t("calculator-mixes.type") }}:</span
          >
          <b-form-select v-model="selected" :options="optionsSelected" plain />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="row justify-content-center align-items-center mb-3">
          <label for="calculatorToggle" class="mb-0">
            {{ $t("calculator-landing.isNewUser") }}
          </label>
          <b-form-checkbox
            class="ml-2 mr-n2"
            v-model="isNewUser"
            name="is-new-user-toggle"
            switch
            size="md"
          >
          </b-form-checkbox>
        </div>
        <p class="mb-2">
          {{
            $t("calculator-landing.isNewUserDescription", {
              cobroMinimo: comision,
              montoMinimo: montoMinimo,
            })
          }}
        </p>

        <!--Tabla de Operaciones-->
        <div
          class="col-sm-12 sub-container-focus"
          v-for="(item, index) in getArrayOperations"
          :key="index"
          :slot="item"
        >
          <span class="sub-container-focus-text">
            {{ $t("calculator-mixes.receive") }}:
          </span>
          <div class="row justify-content-between align-items-center">
            <!-- Caja De Selección de Modalidad -->
            <div class="col-sm-8 col-lg-3 mb-2">
              <b-form-select
                v-model="item.selected"
                :options="item.options"
                :disabled="
                  (!!item.selected && item.options.length === 1) ||
                  selected === ''
                "
                @change="changeOptions({ selected, item })"
                plain
              />
            </div>
            <div class="col-sm-8 col-lg-7">
              <div class="row justify-content-center align-items-center">
                <div class="col-sm-12">
                  <b-input-group>
                    <b-form-input
                      v-model="item.monto"
                      :disabled="!item.selected || selected === ''"
                      type="text"
                      class="form-control text-right"
                      :formatter="formatter"
                      @input="formatterValue({ item, selected })"
                      @click="cursorHandler"
                    />
                    <b-input-group-append v-if="item.monto !== '$0,00'" is-text>
                      <span v-if="item.comisionPaypal">
                        + {{ item.comisionPaypal }}
                      </span>
                      <span v-if="item.comisionFlag"> + {{ comision }} </span>
                      <span v-if="item.comision && item.comision !== '$0,00'">
                        + {{ item.comision }}
                      </span>
                      <span class="ml-1" v-if="item.selected === 'Bolivares'">
                        x Bs.
                        {{ item.rateVES.toString().replace(".", ",") }}</span
                      >
                      <span v-if="item.selected !== 'Bolivares'" class="ml-1">
                        = {{ item.result }}
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div
                  v-if="item.selected === 'Bolivares'"
                  class="col-sm-2 icon-arrow-down-container my-2"
                >
                  <feather type="arrow-down" />
                </div>
                <div class="col-sm-12 my-2">
                  <b-form-input
                    v-if="item.selected === 'Bolivares'"
                    v-model="item.result"
                    :disabled="!item.selected"
                    type="text"
                    class="form-control text-right"
                    :formatter="formatterBs"
                    @input="calcularBolivaresToMonto({ item, selected })"
                    @click="cursorHandler"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-2">
              <img
                class="button"
                src="../../../../assets/img/calculators/remove-button.png"
                @click="
                  deleteItem({ index, optionsSelected, selection: selected })
                "
              />
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row justify-content-end">
            <img
              class="button"
              src="../../../../assets/img/calculators/add-button.png"
              @click="addNewOperation(selected)"
            />
          </div>
        </div>
        <div class="col-12 col-sm-8 col-md-6 sub-container-focus mx-4 p-4">
          <div class="row align-items-center justify-content-between">
            <span class="sub-container-focus-text ml-4">
              {{ $t("calculator-mixes.send") }}:
            </span>
            <span class="sub-container-result-text mr-4" v-if="getComisionFlag">
              {{ getResultSinComision }} + $2,00 = {{ getResult }}
            </span>
            <span v-else class="sub-container-result-text mr-4">
              {{ getResult }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dinero from "dinero.js";
const { helpers } = require("vuelidate/lib/validators");
import { mapGetters, mapMutations, mapActions } from "vuex";

const notAlpha = helpers.regex("notAlpha", /[^A-Za-z]+$/);

export default {
  props: {
    optionsSelected: {
      default: [],
    },
    comisionData: {
      default: {},
    },
  },
  data() {
    return {
      selected: "",
      isNewUser: false,
      comision: "",
      montoMinimo: "",
    };
  },
  created() {
    this.comision = Dinero({
      amount: this.comisionData.comision ?? 200,
      currency: this.selected === "Euro" ? "EUR" : "USD",
    })
      .toFormat("$0.00")
      .replace(".", ",");
    this.montoMinimo = Dinero({
      amount: this.comisionData.montoMinimo ?? 5000,
      currency: this.selected === "Euro" ? "EUR" : "USD",
    })
      .toFormat("$0.00")
      .replace(".", ",");
  },
  computed: {
    ...mapGetters([
      "getArrayOperations",
      "getResult",
      "getResultSinComision",
      "getComisionFlag",
    ]),
  },
  destroyed() {
    this.destroy();
  },
  watch: {
    isNewUser(newVal) {
      this.setNewUserFlag(newVal);
      const operationsArray = this.getArrayOperations;
      operationsArray.forEach((ele) => {
        this.formatterValue({ item: ele, selected: this.selected });
      });
    },
    selected(newVal, oldVal) {
      let operationsArray = this.getArrayOperations;
      const optionsSelected = [...this.optionsSelected, "Bolivares"];

      this.comision = Dinero({
        amount: this.comisionData.comision ?? 200,
        currency: newVal === "Euro" ? "EUR" : "USD",
      })
        .toFormat("$0.00")
        .replace(".", ",");
      this.montoMinimo = Dinero({
        amount: this.comisionData.montoMinimo ?? 5000,
        currency: newVal === "Euro" ? "EUR" : "USD",
      })
        .toFormat("$0.00")
        .replace(".", ",");

      let optionsOperations = optionsSelected.filter(
        (selected) => selected !== newVal
      );
      this.setOptionsOperations(optionsOperations);

      if (operationsArray.length > 0) {
        operationsArray = operationsArray.filter(
          (ele) => ele.selected !== newVal
        );
        operationsArray.forEach((element) => {
          element.options = optionsSelected.filter((ele) => ele !== newVal);
        });

        if (newVal === "Euro" || oldVal === "Euro") {
          operationsArray = [];
        }

        this.setArrayOperations(operationsArray);

        operationsArray.forEach((ele) => {
          this.formatterValue({ item: ele, selected: newVal });
        });
      }
    },
  },
  methods: {
    ...mapMutations([
      "setArrayOperations",
      "addNewOperation",
      "setOptionsOperations",
      "changeOptions",
      "formatterValue",
      "setOptionsOperations",
      "setNewUserFlag",
      "destroy",
    ]),
    ...mapGetters(["getRateData", "getOptionsOperations"]),
    ...mapActions(["formatterValue", "deleteItem", "calcularBolivaresToMonto"]),
    cursorHandler(event) {
      event.target.selectionStart = 10000;
      event.target.selectionEnd = 10000;
      return event;
    },
    formatter(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          amount = amount.multiply(-1);
        }
        result = amount.toFormat("$0.00").replace(".", ",");
      }
      return result;
    },
    formatterBs(value) {
      let result = "Bs. 0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        let amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "VES",
          precision: 2,
        });
        if (amount.isNegative()) {
          amount = amount.multiply(-1);
        }
        result = amount
          .toFormat("$0.00")
          .replace(".", ",")
          .replace("VES", "Bs.");
      }
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
p {
  line-height: 1.5 !important;
}
.rates-title {
  font-size: 16px;
  text-align: right !important;
  line-height: 1.3 !important;
  margin-bottom: 0 !important;
}
.rates {
  font-size: 12px;
  text-align: right !important;
  line-height: 1.3 !important;
  margin-bottom: 0 !important;
}
.calculator-container {
  background: #f7f7f8;
  border-radius: 20px;
  font-size: "12px";
}
.sub-container {
  margin: 25px 0px;
  padding: 10px;
  background: #30735a;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sub-container-focus {
  margin: 25px 0px;
  padding: 10px;
  background: #30735a;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.sub-container-focus-text-container {
  width: 30%;
}
.sub-container-focus-text {
  color: #fff;
  font-size: 14px;
}
.sub-container-result-text {
  color: #fff;
  font-size: 24px;
  text-align: center;
}
.form-sub-container {
  width: 40%;
}
.input {
  background-color: #30735a !important;
  color: #fff !important;
  border: none !important;
}
.info-text {
  font-size: 12px;
}
.button {
  cursor: pointer;
}
.input-group-text {
  font-size: 14px;
  background-color: #30735a;
  color: #ffffff;
  border-radius: 0rem;
  border: none;
}
.icon-arrow-down-container {
  text-align: center;
}
</style>
