<template>
  <div
    :class="[width >= 1068 ? 'video-container' : 'video-container-responsive']"
  >
    <youtube
      :video-id="videoId"
      :player-vars="playerVars"
      :resize="true"
      :fitParent="true"
    />
  </div>
</template>
      
<script>
export default {
  name: "YoutubeVideo",
  data() {
    return {
      videoId: "w2fItyu2BRI",
      playerVars: {
        autoplay: 0,
      },
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
.video-container {
  margin: 0 auto 60px auto;
  max-width: 50%;
}
.video-container-responsive {
  margin: 0 auto 60px auto;
  max-width: 80%;
}
</style>
