import Dinero from "dinero.js";

export default {
    setComisionFlag(state, commit) {
        state.comisionFlag = commit;
    },
    setIsMixesEnv(state, commit) {
        state.isMixesEnv = commit;
    },
    destroy(state) {
        state.arrayOperations = [{ selected: "", monto: "$0,00", rate: 0, options: ["Paypal", "USDT", "Venmo", "Zelle", "Zinli", "Bolivares"], comision: "", result: "$0,00" }];
        //state.optionsOperations = [];
        // state.ratesData = {};
        state.isNewUser = false;
        state.comisionFlag = false;
        state.result = "$0,00";
        state.toSend = null;
    },
    setNewUserFlag(state, commit) {
        state.isNewUser = commit;
    },
    setToSend(state, commit) {
        state.toSend = commit;
    },
    setResiduo(state, commit) {
        state.residuoEnBsS = commit;
    },
    setArrayOperations: (state, commit) => {
        state.arrayOperations = commit;
    },
    setRateData: (state, commit) => {
        state.ratesData = commit;
    },
    addNewOperation: (state, selected) => {
        if (state.arrayOperations.length < 7 && !!selected) {
            const optionsUsed = [selected];
            state.arrayOperations.forEach((ele) => {
                optionsUsed.push(ele.selected);
            });
            const options = state.optionsOperations.filter((option) => !optionsUsed.some((ele) => ele === option));
            state.arrayOperations.push({ selected: "", monto: selected === "Euro" ? "€0,00" : "$0,00", rate: 0, options, comision: "", result: "" })
        }
    },
    setOptionsOperations: (state, commit) => {
        state.optionsOperations = commit;
    },
    changeOptions: (state, { selected, item }) => {

        if (item.selected === "Bolivares") {
            item.result = "Bs. 0,00"
        }

        const optionsUsed = [selected];
        state.arrayOperations.forEach((ele) => {
            optionsUsed.push(ele.selected);
        });

        state.arrayOperations.forEach((ele) => {
            ele.options = state.optionsOperations.filter((item) => item === ele.selected || !optionsUsed.some((x) => x === item));
        });
    },
    setResult: (state, selected) => {
        let result = Dinero({ currency: selected === "Euro" ? "EUR" : "USD" });

        state.arrayOperations.forEach((ele) => {

            if (ele.monto === "$0,00" || ele.monto === "€0,00") {
                return;
            }

            const option = { currency: result.getCurrency() };
            if (ele.selected === "Bolivares") {
                option.amount = parseInt(ele.monto.replace(/[,.$€Bs\s]/g, ""), 10);
            } else {
                option.amount = parseInt(ele.result.replace(/[,.$€Bs\s]/g, ""), 10);
            }

            const resultEle = Dinero(option);
            result = result.add(resultEle);
        });


        const comisionFlag = !state.toSend && result.lessThan(Dinero({ amount: state.ratesData.comision.montoMinimo ?? 5000, currency: result.getCurrency() })) && !state.isNewUser; 
        state.comisionFlag = comisionFlag;

        if (comisionFlag) {
            state.resultSinComision = result.toFormat("$0.00").replace(".", ",");
            result = result.add(Dinero({ amount: state.ratesData.comision.comision ?? 200, currency: result.getCurrency() }));
        }

        
        state.result = result.toFormat("$0.00").replace(".", ",");
    },
};