<template>
  <!-- Start Separator Area -->
  <section>
    <div v-if="width >= 1068" class="separator" />
    <div v-else class="separator-responsive" />
  </section>
  <!-- End Separator Area -->
</template>

<script>
export default {
  name: "Separator",
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
.separator {
  padding: 40px;
  background-color: #00be9c;
  margin: -50px 0 60px 0;
}
.separator-responsive {
  padding: 40px;
  background-color: #00be9c;
  margin-bottom: 60px;
}
</style>