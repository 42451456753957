import { render, staticRenderFns } from "./HowToSwap.vue?vue&type=template&id=0bf35a26&scoped=true"
import script from "./HowToSwap.vue?vue&type=script&lang=js"
export * from "./HowToSwap.vue?vue&type=script&lang=js"
import style0 from "./HowToSwap.vue?vue&type=style&index=0&id=0bf35a26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf35a26",
  null
  
)

export default component.exports