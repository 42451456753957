<template>
  <div>
    <!-- Start Calculadora PayPal Page Title -->
    <div class="page-title-area">
      <div class="container">
        <div class="section-title">
          <h2 style="text-align: center">{{ $t("calculators.title1") }}</h2>
          <div class="bar"></div>
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-md-2 col-sm-12 mb-4">
              <img
                src="../../../../assets/img/calculators/paypal.png"
                alt="paypal logo"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mx-4">
              <p class="description">
                {{ $t("calculators.description1-1") }} {{ rate }}%
                <span v-if="rateFixed != '$0,00'">+ {{ rateFixed }} </span
                >{{ $t("calculators.description1-2") }}.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div class="shape1">
        <img src="../../../../assets/img/shape1.png" alt="shape" />
      </div>
      <div class="shape2 rotateme">
        <img src="../../../../assets/img/shape2.svg" alt="shape" />
      </div>
      <div class="shape3">
        <img src="../../../../assets/img/shape3.svg" alt="shape" />
      </div>
      <div class="shape4">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape5">
        <img
          src="../../../../assets/img/shape5.png"
          style="width: 150px"
          alt="shape"
        />
      </div>
      <div class="shape6 rotateme">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape7">
        <img src="../../../../assets/img/shape4.svg" alt="shape" />
      </div>
      <div class="shape8 rotateme">
        <img src="../../../../assets/img/shape2.svg" alt="shape" />
      </div>
      -->
    </div>
    <!-- End Calculadora PayPal Page Title -->

    <!-- Start Comisión PayPal Area -->
    <div class="mb-5">
      <h3 class="mb-3" style="text-align: center">
        {{ $t("calculators.subtitle1-1") }}
      </h3>
      <form>
        <div class="row justify-content-center align-items-center mx-1 mb-3">
          <div class="col-lg-4 col-sm-12">
            <b-form-select
              v-model="feeType"
              :options="feeTypeOptions"
              @input="formatterFeeType"
              plain
            />
          </div>
        </div>
        <div class="row justify-content-center align-items-center mx-3">
          <div class="col-lg-2 col-sm-12">
            <div class="input-group text-right">
              <b-form-input
                v-model="rate"
                type="text"
                class="form-control text-right"
                :formatter="formatterRate"
                @click="cursorHandler"
              />
              <span class="append">%</span>
            </div>
          </div>
          +
          <div class="col-lg-2 col-sm-12">
            <b-form-input
              v-model="rateFixed"
              type="text"
              class="form-control text-right"
              :formatter="formatterRateUSD"
              @click="cursorHandler"
            />
          </div>
        </div>
      </form>
    </div>
    <!-- End Comisión PayPal Area -->

    <!-- Inicio de mensaje de comision por - de 50$ -->

    <div
      v-if="!outVexFlag"
      class="row justify-content-center align-items-center mx-1 mb-3"
    >
      <label for="paypalCalculatorToggle" class="mb-0">
        {{ $t("calculators.isNewUser") }}
      </label>
      <b-form-checkbox
        class="ml-2 mr-n2"
        id="paypalCalculatorToggle"
        v-model="isNewUser"
        name="is-new-user-toggle"
        switch
        size="md"
      >
      </b-form-checkbox>
    </div>

    <div
      v-if="!outVexFlag"
      class="row justify-content-center align-items-center mx-1 my-3"
    >
      <div class="col-lg-4 col-sm-12 mb-3">
        <p>{{ $t("calculator-landing.isNewUserDescription", {
              cobroMinimo: ce,
              montoMinimo: montoMinimo,
            }) }}.</p>
      </div>
    </div>

    <!-- Fin de mensaje de comision por - de 50$ -->

    <!-- Start Calculadora De Comisión PayPal Area -->
    <div class="row justify-content-center align-items-center mx-3 mb-3">
      <!-- Start Enviar Area -->
      <div class="col-lg-4 col-sm-12 mb-3">
        <div class="d-flex justify-content-start">
          <h3 class="mb-3">
            {{ $t("calculators.subtitle1-3") }}
          </h3>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.receive1-1") }}</p>
          <div class="form-group w-50 mb-0">
            <b-form-input
              v-model="sendUsdPayPalOpe1"
              type="text"
              class="form-control text-right"
              :formatter="formatterSend"
              @click="cursorHandler"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.receive1-2") }}</p>
          <p>{{ sendUsdPayPalOpe2 }}</p>
        </div>

        <div
          v-if="sendFlag"
          class="d-flex justify-content-between align-items-center mb-3"
        >
          <p class="mb-0">{{ $t("calculators.ce") }}</p>
          <p>{{ ce }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">{{ $t("calculators.receive1-3") }}</p>
          <p>{{ sendUsdPayPalOpe3 }}</p>
        </div>
      </div>
      <!-- End Enviar Area -->

      <!-- Start Recibir Area -->
      <div class="col-lg-4 col-sm-12 mb-3">
        <div class="d-flex justify-content-start">
          <h3 class="mb-3">
            {{ $t("calculators.subtitle1-2") }}
          </h3>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.send1-1") }}</p>
          <div class="form-group w-50 mb-0">
            <b-form-input
              v-model="receiveUsdPayPalOpe1"
              type="text"
              class="form-control text-right"
              :formatter="formatterReceiver"
              @click="cursorHandler"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <p class="mb-0">{{ $t("calculators.send1-2") }}</p>
          <p>{{ receiveUsdPayPalOpe2 }}</p>
        </div>
        <div
          v-if="receiverFlag"
          class="d-flex justify-content-between align-items-center mb-3"
        >
          <p class="mb-0">{{ $t("calculators.ce") }}</p>
          <p>{{ ce }}</p>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">{{ $t("calculators.send1-3") }}</p>
          <p>{{ receiveUsdPayPalOpe3 }}</p>
        </div>
      </div>
      <!-- End Recibir Area -->
    </div>
    <!-- End Calculadora De Comisión PayPal Area -->
  </div>
</template>

<script>
import Dinero from "dinero.js";

const { helpers } = require("vuelidate/lib/validators");
const notAlpha = helpers.regex("notAlpha", /[^A-Za-z]+$/);
export default {
  name: "PaypalCalculator",
  props: {
    rates: {
      default: "",
    },
  },
  data() {
    return {
      ce: "$2,00",
      montoMinimo: "",
      comision: {},
      isNewUser: false,
      outVexFlag: false,
      sendFlag: false,
      receiverFlag: false,
      rate: "",
      rateUSD: "",
      rateFixed: "",
      sendUsdPayPalOpe1: "$0,00",
      sendUsdPayPalOpe2: "$0,00",
      sendUsdPayPalOpe3: "$0,00",
      receiveUsdPayPalOpe1: "$0,00",
      receiveUsdPayPalOpe2: "$0,00",
      receiveUsdPayPalOpe3: "$0,00",
      feeType: this.$t("calculators.sendPaypal"),
      feeTypeOptions: [
        this.$t("calculators.sendPaypal"),
        this.$t("calculators.receivePaypal"),
      ],
    };
  },
  created() {
    const { rate, rateUSD, client, comision } = this.rates;
    this.rate = rate;
    this.comision = {
      comision: Dinero({ amount: comision.comision }),
      montoMinimo: Dinero({ amount: comision.montoMinimo })
    };

    this.ce = this.comision.comision.toFormat("$0.00").replace(".", ",");
    this.montoMinimo = this.comision.montoMinimo.toFormat("$0.00").replace(".", ",");

    this.rateUSD = Dinero({
      amount: parseInt(rateUSD.replace(/[,.$€\s]/g, ""), 10),
    });
    this.vex = {
      rate,
      rateUSD: this.rateUSD,
    };
    this.client = {
      rate: client.rate,
      rateUSD: Dinero({
        amount: parseInt(client.rateUSD.replace(/[,.$€\s]/g, ""), 10),
      }),
    };
    this.rateFixed = this.rateUSD.toFormat("$0.00").replace(".", ",");
  },
  beforeUpdate() {
    this.feeType = this.$t("calculators.sendPaypal");
    this.feeTypeOptions = [
      this.$t("calculators.sendPaypal"),
      this.$t("calculators.receivePaypal"),
    ];
  },
  watch: {
    isNewUser() {
      const rate = parseFloat(this.rate.replace(",", "."), 10);
      const { rateUSD } = this;

      const receiveUsdPayPalOpe1 = Dinero({
        amount: parseInt(
          this.receiveUsdPayPalOpe1.replace(/[,.$€\s]/g, ""),
          10
        ),
      });
      const sendUsdPayPalOpe1 = Dinero({
        amount: parseInt(this.sendUsdPayPalOpe1.replace(/[,.$€\s]/g, ""), 10),
      });

      // para enviar
      const totalSend = this.operacionRecibir(
        rate,
        rateUSD,
        receiveUsdPayPalOpe1
      );
      if (
        totalSend.total.isPositive() &&
        !totalSend.total.isZero() &&
        totalSend.comision.isPositive() &&
        !totalSend.comision.isZero()
      ) {
        this.receiveUsdPayPalOpe3 = totalSend.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.receiveUsdPayPalOpe2 = totalSend.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.receiveUsdPayPalOpe3 = "N/A";
        this.receiveUsdPayPalOpe2 = "N/A";
      }

      // para recibir
      const totalReceive = this.operacionEnviar(
        rate,
        rateUSD,
        sendUsdPayPalOpe1
      );

      if (
        totalReceive.total.isPositive() &&
        !totalReceive.total.isZero() &&
        totalReceive.comision.isPositive() &&
        !totalReceive.comision.isZero()
      ) {
        this.sendUsdPayPalOpe3 = totalReceive.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.sendUsdPayPalOpe2 = totalReceive.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.sendUsdPayPalOpe3 = "N/A";
        this.sendUsdPayPalOpe2 = "N/A";
      }
    },
  },
  methods: {
    cursorHandler(event) {
      event.target.selectionStart = 10000;
      event.target.selectionEnd = 10000;
      return event;
    },
    formatterRate(value) {
      let result = "0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        const amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          result = amount.multiply(-1).toUnit().toFixed(2).replace(".", ",");
        }
        if (amount.greaterThan(Dinero({ amount: 10000 }))) {
          result = "100,00";
        }
        result = amount.toUnit().toFixed(2).replace(".", ",");
      }
      this.rateCalculator(result);
      return result;
    },
    formatterRateUSD(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        const amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          result = amount.multiply(-1).toFormat("$0.00").replace(".", ",");
        }
        result = amount.toFormat("$0.00").replace(".", ",");
      }
      this.rateUSDCalculator(result);
      return result;
    },
    formatterSend(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        const amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          result = amount.multiply(-1).toFormat("$0.00").replace(".", ",");
        }
        result = amount.toFormat("$0.00").replace(".", ",");
      }
      this.sendUsdCalculator(result);
      return result;
    },
    formatterReceiver(value) {
      let result = "$0,00";
      if (value && notAlpha(value) && value.replace(/[,.$€Bs\s]/g, "") !== "") {
        const amount = Dinero({
          amount: parseInt(value.replace(/[,.$€Bs\s]/g, ""), 10),
          currency: "USD",
          precision: 2,
        });
        if (amount.isNegative()) {
          result = amount.multiply(-1).toFormat("$0.00").replace(".", ",");
        }
        result = amount.toFormat("$0.00").replace(".", ",");
      }
      this.receiverCalculator(result);
      return result;
    },
    formatterFeeType(fromTo) {
      this.outVexFlag = fromTo !== this.$t("calculators.sendPaypal");
      if (!this.outVexFlag) {
        this.rate = this.vex.rate;
        this.rateFixed = this.vex.rateUSD.toFormat("$0.00").replace(".", ",");
        this.rateUSD = this.vex.rateUSD;
      } else {
        this.rate = this.client.rate;
        this.rateFixed = this.client.rateUSD
          .toFormat("$0.00")
          .replace(".", ",");
        this.rateUSD = this.client.rateUSD;
      }
      this.rateCalculator(this.rate);
    },
    receiverCalculator(newVal) {
      const rate = parseFloat(this.rate.replace(",", "."), 10);
      const { rateUSD } = this;
      const receiveUsdPayPalOpe1 = Dinero({
        amount: parseInt(newVal.replace(/[,.$€\s]/g, ""), 10),
      });

      // para enviar
      const totalSend = this.operacionRecibir(
        rate,
        rateUSD,
        receiveUsdPayPalOpe1
      );
      if (
        totalSend.total.isPositive() &&
        !totalSend.total.isZero() &&
        totalSend.comision.isPositive() &&
        !totalSend.comision.isZero()
      ) {
        this.receiveUsdPayPalOpe3 = totalSend.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.receiveUsdPayPalOpe2 = totalSend.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.receiveUsdPayPalOpe3 = "N/A";
        this.receiveUsdPayPalOpe2 = "N/A";
      }
    },
    sendUsdCalculator(newVal) {
      const rate = parseFloat(this.rate.replace(",", "."), 10);
      const { rateUSD } = this;
      const sendUsdPayPalOpe1 = Dinero({
        amount: parseInt(newVal.replace(/[,.$€\s]/g, ""), 10),
      });

      // para recibir
      const totalReceive = this.operacionEnviar(
        rate,
        rateUSD,
        sendUsdPayPalOpe1
      );

      if (
        totalReceive.total.isPositive() &&
        !totalReceive.total.isZero() &&
        totalReceive.comision.isPositive() &&
        !totalReceive.comision.isZero()
      ) {
        this.sendUsdPayPalOpe3 = totalReceive.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.sendUsdPayPalOpe2 = totalReceive.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.sendUsdPayPalOpe3 = "N/A";
        this.sendUsdPayPalOpe2 = "N/A";
      }
    },
    rateUSDCalculator(newVal) {
      const rateUSD = Dinero({
        amount: parseInt(newVal.replace(/[,.$€\s]/g, ""), 10),
      });

      const rate = parseFloat(this.rate.replace(",", "."), 10);

      const receiveUsdPayPalOpe1 = Dinero({
        amount: parseInt(
          this.receiveUsdPayPalOpe1.replace(/[,.$€\s]/g, ""),
          10
        ),
      });
      const sendUsdPayPalOpe1 = Dinero({
        amount: parseInt(this.sendUsdPayPalOpe1.replace(/[,.$€\s]/g, ""), 10),
      });

      // para enviar
      const totalSend = this.operacionRecibir(
        rate,
        rateUSD,
        receiveUsdPayPalOpe1
      );
      if (
        totalSend.total.isPositive() &&
        !totalSend.total.isZero() &&
        totalSend.comision.isPositive() &&
        !totalSend.comision.isZero()
      ) {
        this.receiveUsdPayPalOpe3 = totalSend.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.receiveUsdPayPalOpe2 = totalSend.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.receiveUsdPayPalOpe3 = "N/A";
        this.receiveUsdPayPalOpe2 = "N/A";
      }

      // para recibir
      const totalReceive = this.operacionEnviar(
        rate,
        rateUSD,
        sendUsdPayPalOpe1
      );

      if (
        totalReceive.total.isPositive() &&
        !totalReceive.total.isZero() &&
        totalReceive.comision.isPositive() &&
        !totalReceive.comision.isZero()
      ) {
        this.sendUsdPayPalOpe3 = totalReceive.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.sendUsdPayPalOpe2 = totalReceive.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.sendUsdPayPalOpe3 = "N/A";
        this.sendUsdPayPalOpe2 = "N/A";
      }

      this.rateUSD = rateUSD;
    },
    rateCalculator(newVal) {
      const rate = parseFloat(newVal.replace(",", "."), 10);
      const { rateUSD } = this;

      const receiveUsdPayPalOpe1 = Dinero({
        amount: parseInt(
          this.receiveUsdPayPalOpe1.replace(/[,.$€\s]/g, ""),
          10
        ),
      });
      const sendUsdPayPalOpe1 = Dinero({
        amount: parseInt(this.sendUsdPayPalOpe1.replace(/[,.$€\s]/g, ""), 10),
      });

      // para enviar
      const totalSend = this.operacionRecibir(
        rate,
        rateUSD,
        receiveUsdPayPalOpe1
      );
      if (
        totalSend.total.isPositive() &&
        !totalSend.total.isZero() &&
        totalSend.comision.isPositive() &&
        !totalSend.comision.isZero()
      ) {
        this.receiveUsdPayPalOpe3 = totalSend.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.receiveUsdPayPalOpe2 = totalSend.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.receiveUsdPayPalOpe3 = "N/A";
        this.receiveUsdPayPalOpe2 = "N/A";
      }

      // para recibir
      const totalReceive = this.operacionEnviar(
        rate,
        rateUSD,
        sendUsdPayPalOpe1
      );

      if (
        totalReceive.total.isPositive() &&
        !totalReceive.total.isZero() &&
        totalReceive.comision.isPositive() &&
        !totalReceive.comision.isZero()
      ) {
        this.sendUsdPayPalOpe3 = totalReceive.comision
          .toFormat("$0.00")
          .replace(".", ",");
        this.sendUsdPayPalOpe2 = totalReceive.total
          .toFormat("$0.00")
          .replace(".", ",");
      } else {
        this.sendUsdPayPalOpe3 = "N/A";
        this.sendUsdPayPalOpe2 = "N/A";
      }
    },
    operacionEnviar(rate, rateUSD, usd) {
      let send = usd.convertPrecision(10);
      const newRateUSD = rateUSD.convertPrecision(10);

      this.sendFlag =
        send.lessThan(this.comision.montoMinimo) &&
        !this.outVexFlag &&
        !this.isNewUser;

      if (this.sendFlag) {
        send = send.add(this.comision.comision);
      }

      const total = send.add(newRateUSD).divide(1 - rate / 100);
      const comision = total.subtract(send);

      return { total, comision };
    },
    operacionRecibir(rate, rateUSD, usd) {
      const receive = usd.convertPrecision(10);
      const newRateUSD = rateUSD.convertPrecision(10);

      const comision = receive.multiply(rate / 100).add(newRateUSD);
      let total = receive.subtract(comision);

      this.receiverFlag =
        total.lessThan(this.comision.montoMinimo) &&
        !this.outVexFlag &&
        !this.isNewUser;
      if (this.receiverFlag) {
        total = total.subtract(this.comision.comision);
      }

      return { total, comision };
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  max-width: 700px;
  margin: 0 auto;
}
.append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  // background-color: #e9ecef;
  border: 1px solid #eee;
  // border-radius: 0.25rem;
}
</style>
