<template>
  <!-- Start Our Solutions Area -->
  <section class="solutions-area">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">{{ $t("solutions.subtitle") }}</span>
        <h2>{{ $t("solutions.title") }}</h2>
        <div class="bar"></div>
      </div>

      <div class="row justify-content-center align-items-center mx-4">
        <img
          v-if="width >= 1068"
          class="the-girl"
          src="../../../assets/img/our-services/the-girl.png"
          alt="image"
        />
        <div class="col-lg-3 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="icon mb-4 my-4">
              <img src="../../../assets/img/our-services/1.svg" alt="image" />
            </div>
            <h3>{{ $t("solutions.service-1-title") }}</h3>
            <p>{{ $t("solutions.service-1-body") }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="icon mb-4">
              <img src="../../../assets/img/our-services/2.png" alt="image" />
            </div>
            <h3>{{ $t("solutions.service-2-title") }}</h3>
            <p>{{ $t("solutions.service-2-body") }}</p>
          </div>
        </div>

        <!-- <div class="col-lg-3 col-sm-6 col-md-6">
          <div class="single-ml-services-box">
            <div class="icon mb-4">
              <img src="../../../assets/img/our-services/4.svg" alt="image" />
            </div>
            <h3>{{ $t("solutions.service-4-title") }}</h3>
            <p>{{ $t("solutions.service-4-body") }}</p>
          </div>
        </div> -->
      </div>
    </div>

    <!--
    <div class="shape1">
      <img src="../../../assets/img/shape1.png" alt="shape" />
    </div>
    <div class="shape2 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    <div class="shape3">
      <img src="../../../assets/img/shape3.svg" alt="shape" />
    </div>
    <div class="shape4">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape6 rotateme">
      <img src="../../../assets/img/shape6.svg" alt="shape" />
    </div>
    <div class="shape7">
      <img src="../../../assets/img/shape4.svg" alt="shape" />
    </div>
    <div class="shape8 rotateme">
      <img src="../../../assets/img/shape2.svg" alt="shape" />
    </div>
    -->
  </section>
  <!-- End Our Solutions Area -->
</template>

<script>
export default {
  name: "Solutions",
  data() {
    return { width: window.innerWidth, height: window.innerHeight };
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style scoped>
h2,
h3 {
  text-align: center;
}
.the-girl {
  margin: 190px 0px 0px 0px;
  width: 250px;
}
</style>